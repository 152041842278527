import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {Box} from "@mui/material";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

const DiamondAnimation = () => {

    useEffect(() => {
        // Dynamically create and append the script tag
        const script = document.createElement("script");
        script.src = `/diamond.js`;
        script.async = true; // Ensures non-blocking loading
        document.body.appendChild(script);

        // Cleanup the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Box className="container" style={{ position: 'relative', maxWidth: '100%', display: 'flex', justifyContent: 'center'}}
            sx={{
                top: {
                    xs: '10%',
                    lg: '0%'
                },
                marginTop: {
                    xs: '10%',
                    lg: '0'
                },
                display: {
                    xs: 'none !important',
                    lg: 'flex !important'
                }
            }}
        >
            <svg className="mainSVG" width="150px" height="150px" viewBox="0 0 600 600" style={{  maxWidth: '100%', visibility: 'hidden'}}>
                <defs>

                    <g id="sparkle" stroke="#FFF" strokeLinecap="round">
                        `
                        <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="39" y2="64.5"/>
                        `
                        <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="39" y2="13"/>
                        `
                        <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="65" y2="38.8"/>
                        `
                        <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="13" y2="38.8"/>
                        <circle fill="none" strokeWidth="0" strokeLinecap="round" strokeMiterlimit="10" cx="39"
                                cy="38.8" r="30"/>
                    </g>

                    <filter id="glow" y="-50%" height="180%">
                        <feGaussianBlur stdDeviation="3" result="coloredBlur"/>
                        <feMerge>
                            <feMergeNode in="coloredBlur"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                    <filter id="neon" y="-50%" height="180%">
                        <feOffset result="offOut" in="SourceGraphic" dx="0" dy="6"/>
                        <feGaussianBlur stdDeviation="6 6" result="coloredBlur"/>
                        <feComponentTransfer>
                            <feFuncR type="table" tableValues="0.41"/>
                            <feFuncG type="table" tableValues="0.71"/>
                            <feFuncB type="table" tableValues="1"/>
                            <feFuncA id="dropFlicker" type="linear" slope="0.6"/>

                        </feComponentTransfer>
                        <feComposite in="SourceGraphic" operator="over"/>
                    </filter>

                    <filter id="drop" x="-150%" y="-150%" width="280%" height="280%">

                        <feGaussianBlur id="gblur" in="offOut" stdDeviation="23" result="blur"/>
                        <feComponentTransfer>
                            <feFuncR type="table" tableValues="0.21"/>
                            <feFuncG type="table" tableValues="0.31"/>
                            <feFuncB type="table" tableValues="1"/>
                            <feFuncA id="dropFlicker" type="linear" slope="1"/>

                        </feComponentTransfer>
                        <feComposite in="SourceGraphic" operator="over"/>

                    </filter>


                </defs>

                <g className="wholeDiamondGroup">
                    <g className="revGroup" opacity="0.05" stroke="#ddeeFF" strokeLinecap="round" strokeWidth="6"
                       fill="none">
                        <polyline id="lineMoveLRev0" points="202,165.5 140,244.5
	300,435 "/>
                        <polyline id="lineMoveLRev1" points="202,165.5 140,244.5
	300,435 "/>

                        <polyline id="lineMoveRRev0" strokeMiterlimit="10" points="299,166 299,244.9 299,435
	"/>
                        <polyline id="lineMoveRRev1" strokeMiterlimit="10" points="299,166 299,244.9 299,435
	"/>

                    </g>
                    <g className="neonGroup" filter="url(#neon)">
                        <g className="frontGroup" stroke="#ddeeFF" strokeLinecap="round" strokeWidth="6" fill="none"
                           filter="url(#drop)">
                            <line id="facetTop" strokeMiterlimit="10" x1="202" y1="165" x2="398" y2="165"/>

                            <polyline id="facetM" stroke="none" strokeMiterlimit="10" points="299,166 299,244.9 299,435
	"/>

                            <polyline id="facetR" points="300,435 460,244.5
	398,165.5 "/>
                            <polyline id="facetRShrink" stroke="none" strokeMiterlimit="10" points="300,435 450,244.5
	388,165.5 "/>
                            <polyline id="facetL" points="202,165.5 140,244.5
	300,435 "/>
                            <polyline id="facetLShrink" stroke="none" strokeMiterlimit="10" points="212,165.5 150,244.5
	300,435 "/>

                            <polyline id="lineMoveL0" points="299,166 299,244.9 299,435
	"/>
                            <polyline id="lineMoveL1" points="299,166 299,244.9 299,435
	"/>

                            <polyline id="lineMoveR0" points="300,435 460,244.5
	398,165.5 "/>
                            <polyline id="lineMoveR1" points="300,435 460,244.5
	398,165.5 "/>
                            <line id="horizontalFacet" x1="140" y1="245" x2="460" y2="245"/>

                        </g>
                    </g>

                </g>


            </svg>


        </Box>
    );
};

export default DiamondAnimation;


// import React, {useEffect} from "react";
// import {gsap} from "gsap";
//
//
// const DiamondAnimation = () => {
//     useEffect(() => {
//         // @ts-ignore
//         gsap.registerPlugin((window as any).MorphSVGPlugin);
//
//         console.log("window.MorphSVGPlugin", (window as any).MorphSVGPlugin);
//
//         MorphSVGPlugin.convertToPath("polyline");
//         gsap.defaults({overwrite: false});
//
//         // Variables for elements
//         const container = document.querySelector(".container");
//         const mainSVG = document.querySelector(".mainSVG");
//         const neonGroup = document.querySelector(".neonGroup");
//         const wholeDiamondGroup = document.querySelector(".wholeDiamondGroup");
//         const facetL = document.querySelector(".facetL");
//         const facetR = document.querySelector(".facetR");
//         const lineMoveL0 = document.querySelector("#lineMoveL0");
//         const lineMoveL1 = document.querySelector("#lineMoveL1");
//         const lineMoveR0 = document.querySelector("#lineMoveR0");
//         const lineMoveR1 = document.querySelector("#lineMoveR1");
//         const facetLPath = "M202,165.5 L140,244.5,300,435";
//         const facetMPath = "M299,166 L299,244.9,299,435";
//         const sparkleArr = [];
//         const sparkleCount = 0;
//         const updateCount = 0;
//         const updateCountMax = 200;
//
//         // Center container
//         gsap.set(container, {
//             position: "relative",
//             display: "flex",
//             justifyContent: "center",
//             margin: "0 auto",
//             top: "50%",
//             left: "50%",
//             xPercent: -50,
//             yPercent: -50,
//         });
//         gsap.set("svg", {visibility: "visible"});
//
//         // Create timelines
//         const l0 = gsap.timeline({repeat: -1});
//         l0.to(lineMoveL0, {
//             duration: 2,
//             morphSVG: {shape: facetLPath},
//             ease: "linear",
//         });
//
//         const l1 = gsap.timeline({repeat: -1});
//         l1.to(lineMoveL1, {
//             duration: 2,
//             morphSVG: {shape: facetLPath},
//             ease: "linear",
//         });
//
//         const r0 = gsap.timeline({repeat: -1});
//         r0.to(lineMoveR0, {
//             duration: 2,
//             morphSVG: {shape: facetMPath},
//             ease: "linear",
//         });
//
//         const r1 = gsap.timeline({repeat: -1});
//         r1.to(lineMoveR1, {
//             duration: 2,
//             morphSVG: {shape: facetMPath},
//             ease: "linear",
//         });
//
//         // Main timeline
//         const mainTl = gsap.timeline();
//         mainTl.add(l0, 0);
//         mainTl.add(l1, 1);
//         mainTl.add(r0, 0);
//         mainTl.add(r1, 1);
//
//         return () => {
//             mainTl.kill();
//             l0.kill();
//             l1.kill();
//             r0.kill();
//             r1.kill();
//         };
//     }, []);
//
//     return (
//         <div className="container">
//             <svg className="mainSVG" width="150px" height="150px" viewBox="0 0 600 600">
//                 <defs>
//                     <g id="sparkle" stroke="#FFF" strokeLinecap="round">
//                         <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="39" y2="64.5"/>
//                         <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="39" y2="13"/>
//                         <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="65" y2="38.8"/>
//                         <line fill="none" strokeWidth="6" x1="39" y1="38.8" x2="13" y2="38.8"/>
//                         <circle fill="none" strokeWidth="0" cx="39" cy="38.8" r="30"/>
//                     </g>
//                 </defs>
//
//                 <g className="wholeDiamondGroup">
//                     <g className="revGroup" opacity="0.05" stroke="#ddeeFF" strokeLinecap="round" strokeWidth="6"
//                        fill="none">
//                         <polyline id="lineMoveL0" points="299,166 299,244.9 299,435"/>
//                         <polyline id="lineMoveL1" points="299,166 299,244.9 299,435"/>
//                         <polyline id="lineMoveR0" points="300,435 460,244.5 398,165.5"/>
//                         <polyline id="lineMoveR1" points="300,435 460,244.5 398,165.5"/>
//                     </g>
//
//                     <g className="neonGroup">
//                         <g
//                             className="frontGroup"
//                             stroke="#ddeeFF"
//                             strokeLinecap="round"
//                             strokeWidth="6"
//                             fill="none"
//                             filter="url(#drop)"
//                         >
//                             <line id="facetTop" x1="202" y1="165" x2="398" y2="165"/>
//                             <polyline id="facetM" points="299,166 299,244.9 299,435"/>
//                             <polyline id="facetL" points="202,165.5 140,244.5 300,435"/>
//                             <polyline id="facetR" points="300,435 460,244.5 398,165.5"/>
//                         </g>
//                     </g>
//                 </g>
//             </svg>
//         </div>
//     );
// };
//
// export default DiamondAnimation;
