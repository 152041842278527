import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    Grid,
    Slider,
    TextField,
    Button,
    Dialog,
    Snackbar,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Checkbox,
    FormControlLabel, IconButton
} from '@mui/material';
import {Autocomplete} from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import {useAccount, useWalletClient} from 'wagmi';
import {AppProps, ERC20_APPRV_ABI, INFINITY_DAYS, LOCKER_ABI, shortNumber} from '../Shared';
import {mainnet} from 'wagmi/chains';
import {useWeb3js, useWeb3jsSigner} from "./useWeb3js";
import LockTimeSlider from "./LockTimeSlider";
import PriceCapSlider from "./PriceCapSlider";
import TokenBalanceSlider from "./TokenBalanceSlider";
import {useNavigate} from "react-router-dom";
import {CheckBox} from "@mui/icons-material";
import RoofingRoundedIcon from "@mui/icons-material/RoofingRounded";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BarChartIcon from "@mui/icons-material/BarChart";

(mainnet.rpcUrls.default.http as unknown as string[]) = [process.env.REACT_APP_ETH_NODE as string];

interface TokenOption {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
    mc: string;
    priceusd: string;
    "24h": string;
    pool: string;
}

const CenterModule: React.FC<AppProps> = ({authStatus}) => {
    const {address} = useAccount();
    const {data: walletClient} = useWalletClient();
    const web3js = useWeb3js({chainId: mainnet.id})
    const web3jsSigner = useWeb3jsSigner({chainId: mainnet.id});

    const [tokenInput, setTokenInput] = useState('');
    const [defaultTokens, setDefaultTokens] = useState([] as TokenOption[]);
    const [tokenOptions, setTokenOptions] = useState([] as TokenOption[]);
    const [selectedToken, setSelectedToken] = useState<TokenOption | null>(null);
    const [isLockNowVisible, setIsLockNowVisible] = useState(false);
    const [userBalance, setUserBalance] = useState<null | bigint>(BigInt(0));
    const [decimals, setDecimals] = useState<number>(18);
    const [userBalanceWorth, setUserBalanceWorth] = useState<null | string>("-");
    const [lockTime, setLockTime] = useState<number>(0);
    const [balancePercentage, setBalancePercentage] = useState<number>(100);
    const [priceMultiplier, setPriceMultiplier] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [inputLoading, setInputLoading] = useState(false);
    const navigate = useNavigate();
    const [lockData, setLockData] = useState<{ hash: string, internalLockId: string, locksTotal: string } | null>(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [tokensToLock, setTokensToLock] = useState<string>("0"); // Added for editable input
    const [emergencyWithdrawEnabled, setEmergencyWithdrawEnabled] = useState<boolean>(false);
    const [approvedLockHash, setApprovedLockHash] = useState<string | null>(null);
    const [approveTxHashX, setApproveTxHashX] = useState<any>(null);

    const sendLockDataToBackend = async (lockDetails: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/new-lock', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lockDetails),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.success) {
                setLockData({hash: data.hash, internalLockId: data.internalLockId, locksTotal: data.locksTotal});
                return {hash: data.hash, internalLockId: data.internalLockId, locksTotal: data.locksTotal};
            } else {
                setShowError('Failed to send lock data');
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        const fetchDefaultTokens = async () => {
            //const response = await fetch('/api/default-tokens'); // Replace with your API endpoint

            const response: any = await fetch(process.env.REACT_APP_API_URL + '/api/user/find-token',
                {
                    method: "GET",
                    credentials: 'include', // Include cookies in the request
                });

            const result = await response.json();

            if (result && result.tokens) {
                // const tokens = [
                //     {
                //         "address": "0x6aa3ecec75ceb388d2e929814ead4fc4cd0648fc",
                //         "name": "Reversal",
                //         "symbol": "RVSL",
                //         "decimals": 18,
                //         "mc": "23050",
                //         "priceusd": "0.02724493",
                //         "24h": "0.0",
                //         "pool": "0x9dc984d61bda00a652699fb8e8c7152cddf34083"
                //     },
                //     {
                //         "address": "0x6aa3ecec75ceb388d2e929814ead4fc4cd0648fc",
                //         "name": "Reversal2",
                //         "symbol": "RVSL",
                //         "decimals": 18,
                //         "mc": "23050",
                //         "priceusd": "0.02724493",
                //         "24h": "0.0",
                //         "pool": "0x9dc984d61bda00a652699fb8e8c7152cddf34083"
                //     }
                // ];
                //await response.json();
                if (result.tokens.length > 0) {
                    setDefaultTokens(result.tokens);
                    setTokenOptions(result.tokens); // Show default tokens initially
                }
            }
        };
        if (address) {
            fetchDefaultTokens();
        }
    }, []);

    const updateLockBackend = async (lockDetails: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/lock-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lockDetails),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.success) {
                setLockData({hash: "", internalLockId: "", locksTotal: ""});
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };


    // Fetch token options from the backend
    const fetchTokens = async (inputValue: string) => {
        // const response = await fetch(`/api/user/find-token?query=${inputValue}`);
        // const result = await response.json();
        if (inputValue.trim() === '') {
            // Show default tokens when input is cleared
            setTokenOptions(defaultTokens);
            return;
        }

        setInputLoading(true);

        const response: any = await fetch(process.env.REACT_APP_API_URL + '/api/user/find-token?query=' + encodeURI(inputValue),
            {
                method: "GET",
                credentials: 'include', // Include cookies in the request
            });

        const result = await response.json();

        setInputLoading(false);

        setTokenOptions(result.tokens as TokenOption[]);
    };

    useEffect(() => {
        if (tokenInput.length > 2) {
            fetchTokens(tokenInput);
        } else if (tokenInput.length === 0) {
            setTokenOptions(defaultTokens);
        }
    }, [tokenInput]);

    const handleTokenSelect = async (event: any, value: any) => {
        if (value === null) {
            return;
        }
        setSelectedToken(value);
        setDecimals(value.decimals);
        setIsLockNowVisible(true);
        await fetchUserBalance(value);
    };

    const fetchUserBalance = async (selectedToken: any) => {

        setUserBalance(null);
        setUserBalanceWorth("-");

        try {
            if (!selectedToken || !address) return;

            // ERC20 token ABI (minimal version with only `balanceOf`)
            const erc20Abi = [
                {
                    constant: true,
                    inputs: [{name: '_owner', type: 'address'}],
                    name: 'balanceOf',
                    outputs: [{name: 'balance', type: 'uint256'}],
                    type: 'function'
                }
            ];

            // Initialize contract instance with web3js
            const tokenContract = new web3js.eth.Contract(erc20Abi, selectedToken.address);

            // Fetch balance using web3js
            const balance: any = await tokenContract.methods.balanceOf(address).call();

            // Convert the balance from Wei to Ether based on token decimals
            //const balanceInEther = web3js.utils.fromWei(balance, 'ether'); // Assumes token has 18 decimals
            //setUserBalance(Number(balanceInEther).toFixed(2));
            setUserBalance(balance);
            const balanceInEther = balance / BigInt(Math.pow(10, selectedToken.decimals));

            // Fetch token worth in USD (simulated, you'd replace this with real API calls)
            const worthInUsd: any = Number(balanceInEther) * Number(selectedToken.priceusd);
            if (worthInUsd !== null) {
                setUserBalanceWorth(worthInUsd.toFixed(2));
            }

            setTokensToLock(((balance ? balance * BigInt(balancePercentage * 100) / BigInt(10000) : BigInt(0)) / BigInt(Math.pow(10, selectedToken.decimals))).toString());
        } catch (error) {
            console.error("Failed to fetch user balance:", error);

            setUserBalance(null);
            setUserBalanceWorth("-");
        }
    };

    // read getLastLockId from the locker smart contract
    const getLastLockId = async (address: any) => {
        try {
            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;
            const lockerContract = new web3js.eth.Contract(LOCKER_ABI, lockerAddress);
            const lastLockId = await lockerContract.methods.getLastLockId(address).call();
            return lastLockId;
        } catch (error) {
            console.error("Failed to fetch last lock id:", error);
            return 0;
        }
    };

    const handleLock = async () => {
        setShowPopup(true); // Show popup with explanation before proceeding
        await handleLockNow();
    };

    const handleSliderChange = (value: number, custom: number = 0) => {
        setBalancePercentage(value);
        if (selectedToken && selectedToken.decimals) {
            if (custom > 0) {
                setTokensToLock(custom.toString());
            } else {
                const updatedValue = ((userBalance ? userBalance * BigInt(value * 100) / BigInt(10000) : BigInt(0)) / BigInt(Math.pow(10, selectedToken.decimals))).toString();
                setTokensToLock(updatedValue);
            }
            setApprovedLockHash(null);
            setApproveTxHashX(null);
        }
    };

    const handleLockNow = async () => {
        if (!selectedToken || !walletClient) return;

        let newLockBackendStatus: any = {};

        try {

            setLoading(true);

            //const tokensToLock = userBalance ? userBalance * BigInt(balancePercentage * 100) / BigInt(10000) : BigInt(0);
            const _tokensToLock = userBalance && tokensToLock ? BigInt(tokensToLock) * BigInt(Math.pow(10, selectedToken.decimals)) : BigInt(0);

            // If time lock is inactive but multiplier is positive then lockTimeLocker should be "infinity"
            let lockTimeLocker = 0;
            if (lockTime > 0) {
                if (lockTime === INFINITY_DAYS) {
                    lockTimeLocker = INFINITY_DAYS * 60 * 60 * 24;
                } else {
                    lockTimeLocker = Math.floor(Date.now() / 1000) + lockTime * 60 * 60 * 24;
                }
            } else {
                if (priceMultiplier > 0) {
                    lockTimeLocker = INFINITY_DAYS * 60 * 60 * 24;
                }
            }

            if (!(lockData?.hash) || !lockData.hash.length) {
                const lockDetails = {
                    tokenAddress: selectedToken.address,
                    owner: address,
                    tokensToLock: _tokensToLock,
                    lockTime: lockTimeLocker,
                    priceToday: selectedToken.priceusd,
                    mcToday: selectedToken.mc,
                    pool: selectedToken.pool,
                    priceMultiplier,
                    balancePercentage,
                    emergencyWithdrawEnabled
                };
                newLockBackendStatus = await sendLockDataToBackend(lockDetails);

                if (!(newLockBackendStatus?.hash) || !newLockBackendStatus.hash.length) {
                    throw new Error("An error occurred while locking tokens. Please try again later.");
                }
            }

            if ((!(lockData?.hash) || !lockData.hash.length) && (!(newLockBackendStatus?.hash) || !newLockBackendStatus.hash.length)) {
                throw new Error("An error occurred while locking tokens. Please try again later. Missing lockData.hash");
            }

            // 1. Approve the tokens for the contract
            const tokenContract = new web3jsSigner.eth.Contract(ERC20_APPRV_ABI, selectedToken.address);
            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;

            const allowance: bigint = await tokenContract.methods.allowance(address, lockerAddress).call();

            // console.log("current allowance", allowance);

            const tokensToAppove = _tokensToLock > allowance ? allowance + (_tokensToLock - allowance) : _tokensToLock;

            const alreadyLocked = lockData?.locksTotal ? BigInt(lockData.locksTotal) : BigInt(newLockBackendStatus.locksTotal);

            // Approve the contract to transfer the tokens on behalf of the user
            const approveTransaction = tokenContract.methods.approve(lockerAddress, tokensToAppove + alreadyLocked);

            // Send approval transaction
            let approveTxHash: any;

            if(!approveTxHashX || approveTxHashX === null) {

                approveTxHash = await approveTransaction.send({
                    from: address,
                });
            } else {
                approveTxHash = approveTxHashX;
            }
            // console.log('Approval successful:', approveTxHash);

            // 2. Interact with the Locker contract to lock the tokens
            const lockerContract = new web3jsSigner.eth.Contract(LOCKER_ABI, lockerAddress);
            /*
            function lockTokens(
                address token,
                uint256 amount,
                uint256 unlockTime,
                bytes32 hashedUnlockKey
            )
             */

            const lockHash = lockData && lockData.hash ? lockData.hash : newLockBackendStatus.hash;

            // Approved successful, store lockHash in state
            setApprovedLockHash(lockHash);
            setApproveTxHashX(approveTxHash);

            if(!lockHash || lockHash === null || lockHash.length === 0) {
                throw new Error("An error occurred while locking tokens. Please try again later. Missing lockHash");
            }

            // console.log("lockTokens tx", selectedToken.address,        // ERC20 token address
            //     tokensToLock,                 // Amount to lock in Wei
            //     lockTimeLocker,               // Duration for lock
            //     lockHash,                     // Provide unlock key as a hashed value
            //     priceMultiplier * 100,
            //     emergencyWithdrawEnabled);

            // Call the lockTokens function on the locker contract
            const lockTransaction = lockerContract.methods.lockTokens(
                selectedToken.address,        // ERC20 token address
                _tokensToLock,                 // Amount to lock in Wei
                lockTimeLocker,               // Duration for lock
                lockHash,                     // Provide unlock key as a hashed value
                Math.round(priceMultiplier * 100),
                emergencyWithdrawEnabled
            );

            // Send the lock transaction
            const lockTxHash: any = await lockTransaction.send({
                from: address,
            });

            setApprovedLockHash(null);
            setApproveTxHashX(null);

            handleClosePopup();

            setShowSuccess(true);

            if (lockData?.internalLockId || newLockBackendStatus?.internalLockId) {
                const lastLockId = await getLastLockId(address);
                await updateLockBackend({
                    internalLockId: lockData?.internalLockId ? lockData.internalLockId : newLockBackendStatus.internalLockId,
                    newStatus: "success",
                    approveTxHash: approveTxHash.transactionHash ? approveTxHash.transactionHash : approveTxHash,
                    lockTxHash: lockTxHash.transactionHash ? lockTxHash.transactionHash : lockTxHash,
                    lockId: lastLockId
                });
            }

            // Redirect to My Locks after successful lock
            //window.location.href = '/my-locks';
        } catch (error) {
            console.error('Error locking tokens', error);
            setShowError('An error occurred while locking tokens. Please try again later.');
            handleClosePopup();

            if (lockData?.internalLockId || newLockBackendStatus?.internalLockId) {
                await updateLockBackend({
                    internalLockId: lockData?.internalLockId ? lockData.internalLockId : newLockBackendStatus.internalLockId,
                    newStatus: "failed"
                });
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle close of success popup
    const handleCloseSuccess = () => {
        setShowSuccess(false);
        navigate('/my-locks');
    };

    // Handle close of error notification
    const handleCloseError = () => {
        setShowError(null);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleCopy = (token: string) => {
        navigator.clipboard.writeText(token);
    };

    return (
        <>
            <Box mt={4}>
                <Grid container spacing={2}>
                    {/* 4-column section */}
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4"
                                    style={{
                                        color: '#fff',
                                        marginLeft: '20px',
                                        marginBottom: '20px',
                                        fontWeight: '900',
                                        lineHeight: '35px'
                                    }}>
                            <AddRoundedIcon
                                sx={{
                                    fontSize: "35px",
                                    color: "#ffffff",
                                    backgroundColor: "#212121",
                                    borderRadius: "50%",
                                    padding: "2px",
                                    marginRight: "8px",
                                    verticalAlign: 'bottom'
                                }}
                            />
                            Create <span className={'pink'}>New Lock</span>
                        </Typography>
                        <Box
                            style={{
                                backgroundColor: 'transparent',
                                padding: '10px 20px',
                                paddingBottom: '5px',
                                borderRadius: '16px',
                                position: 'relative',
                                marginLeft: '10px',
                                marginRight: '10px'
                            }}
                            className="newLockBg"
                        >
                            {authStatus === 'authenticated' ? (
                                <Box display="flex" alignItems="start" my={2}>
                                    <CheckBox fontSize="large" style={{color: '#fff', opacity: '0.5'}}/>
                                    <Typography variant="h6"
                                                style={{color: '#fff', marginLeft: '10px', opacity: '0.5'}}>
                                        Login with your favourite wallet
                                    </Typography>
                                </Box>
                            ) : (
                                <Box display="flex" alignItems="start" my={2}>
                                    <LooksOneIcon fontSize="large" style={{color: '#ffffff'}}/>
                                    <Typography variant="h6" style={{color: '#ffffff', marginLeft: '10px'}}>
                                        Login with your favourite wallet
                                    </Typography>
                                </Box>
                            )}

                            {/* Token Input with Autocomplete */}
                            {authStatus === 'authenticated' && (
                                <>
                                    <Box display="flex" alignItems="start" my={2}>
                                        {(selectedToken) ?
                                            <CheckBox fontSize="large" style={{color: '#fff', opacity: '0.5'}}/> :
                                            <LooksTwoIcon fontSize="large" style={{color: '#ffffff'}}/>}
                                        <Typography variant="h6" style={{
                                            color: '#ffffff',
                                            marginLeft: '10px',
                                            opacity: selectedToken ? '0.5' : '1.0'
                                        }}>
                                            Enter the ERC20 token address you want to lock
                                        </Typography>
                                    </Box>
                                    <Box ml={2} style={{paddingRight: '20px', marginTop: '40px', marginBottom: '20px'}}>
                                        <Autocomplete
                                            options={tokenOptions}
                                            getOptionLabel={(option) => `${option.name} (${option.symbol}) - ${option.address}`}
                                            onInputChange={(event, value) => setTokenInput(value)}
                                            onChange={handleTokenSelect}
                                            loading={inputLoading}
                                            noOptionsText={loading ? 'Loading...' : 'No tokens found'}
                                            sx={{
                                                borderRadius: '16px',
                                                '& .MuiAutocomplete-inputRoot': {
                                                    color: '#ffffff', // Text color for the input
                                                    backgroundColor: '#1f1f22', // Background color
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-paper': {
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-popper': {
                                                    backgroundColor: '#101015', // Background color for the dropdown
                                                    color: '#ffffff', // Text color for the options
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-listbox': {
                                                    backgroundColor: '#101015', // Background color for dropdown list
                                                    color: '#ffffff', // Text color for dropdown options
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-option[data-focus="true"]': {
                                                    backgroundColor: '#343435', // Background color for focused option
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-option[aria-selected="true"]': {
                                                    backgroundColor: '#ff007a', // Background color for selected option
                                                    color: '#ffffff', // Text color for selected option
                                                    borderRadius: '16px'
                                                },
                                                '& .MuiAutocomplete-popupIndicator': {
                                                    color: '#ff000000', // Change color of the dropdown arrow
                                                },
                                                '& .MuiAutocomplete-clearIndicator': {
                                                    color: '#ff007a', // Change color of the clear button
                                                },
                                                '& .MuiAutocomplete-': {
                                                    color: '#ff007a', // Change color of the clear button
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                           label="Paste Token address or start typing the name"
                                                           variant="outlined" fullWidth
                                                           InputLabelProps={{
                                                               sx: {
                                                                   color: '#ffffff', // Default label color
                                                                   backgroundColor: 'transparent !important',
                                                                   '&.Mui-focused': {
                                                                       color: '#ff007a', // Change label color when focused
                                                                       backgroundColor: 'transparent !important'
                                                                   },
                                                               },
                                                           }}
                                                           sx={{
                                                               input: {
                                                                   color: '#ffffff',
                                                                   backgroundColor: 'transparent !important'
                                                               }, // Text color
                                                               label: {color: '#ffffff'}, // Label color
                                                               fieldset: {
                                                                   borderColor: '#ff007a',
                                                                   outlineColor: '#ff007a',
                                                                   backgroundColor: 'transparent !important',
                                                               }, // Border color
                                                               '& .MuiOutlinedInput-root': {
                                                                   '& fieldset': {
                                                                       borderColor: '#ff007a', // Default border color
                                                                       backgroundColor: 'transparent !important',
                                                                   },
                                                                   '&:hover fieldset': {
                                                                       borderColor: '#ff007a', // Border color on hover
                                                                   },
                                                                   '&.Mui-focused fieldset': {
                                                                       borderColor: '#ff007a', // Border color when focused
                                                                   },
                                                                   '&.Mui-active fieldset': {
                                                                       borderColor: '#ff007a', // Border color when active
                                                                   },
                                                               },
                                                               '& .MuiInputBase-root': {
                                                                   backgroundColor: 'transparent !important',
                                                                   borderRadius: '16px',
                                                                   '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                       borderColor: '#ff007a', // Hover border color
                                                                   },
                                                                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                       borderColor: '#ff007a', // Focused border color
                                                                   },
                                                               },
                                                               '&.Mui-focused .MuiInputLabel': {
                                                                   color: '#ff007a', // Border color when focused
                                                               },
                                                           }}/>
                                            )}
                                        />
                                        {selectedToken && (
                                            // <Typography variant="body1" style={{ color: '#ffffff', marginTop: '10px' }}>
                                            //     Name: {selectedToken.name} <br />
                                            //     Address: {selectedToken.address} <br />
                                            //     Current Price: ${selectedToken.priceusd} <br />
                                            //     Market Cap: ${selectedToken.mc}
                                            // </Typography>

                                            <Box sx={{
                                                backgroundColor: 'transparent',
                                                p: 2,
                                                borderRadius: '8px',
                                                color: '#ffffff'
                                            }}>
                                                <Grid container spacing={0}>
                                                    <Grid
                                                        item
                                                        xs={12} // Full width on extra-small screens
                                                        sm={6}  // 2 columns on small screens (6/12)
                                                        md={4}  // 3 columns on medium screens (4/12)
                                                        lg={3}  // 4 columns on large screens (3/12)
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: 0,
                                                                backgroundColor: 'transparent',
                                                                borderRadius: 1
                                                            }}
                                                        >

                                                            {/* Token Name and Address */}
                                                            <Typography variant="h6" sx={{fontWeight: 'bold', mb: 1}}>
                                                                {selectedToken.name}
                                                                <Typography component="div"
                                                                            sx={{
                                                                                fontSize: '0.85rem',
                                                                                color: '#9c9b93',
                                                                                ml: 0
                                                                            }}>
                                                                    {selectedToken.address.slice(0, 5)}...{selectedToken.address.slice(-4)}
                                                                    <IconButton
                                                                        onClick={() => handleCopy(selectedToken.address)}
                                                                        style={{
                                                                            color: 'rgba(255, 255, 255, 0.8)',
                                                                            marginLeft: '5px'
                                                                        }}>
                                                                        <ContentCopyIcon
                                                                            sx={{fontSize: '0.8rem', padding: '4px'}}/>
                                                                    </IconButton>
                                                                </Typography>
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} // Full width on extra-small screens
                                                        sm={6}  // 2 columns on small screens (6/12)
                                                        md={4}  // 3 columns on medium screens (4/12)
                                                        lg={3}  // 4 columns on large screens (3/12)
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: 0,
                                                                backgroundColor: 'transparent',
                                                                borderRadius: 1
                                                            }}
                                                        >
                                                            <Typography variant="h6" sx={{fontWeight: 'bold', mb: 1}}>
                                                                Your Balance
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '0.85rem',
                                                                color: '#9c9b93',
                                                                ml: 0
                                                            }}>
                                                                {
                                                                    userBalance !== null ?
                                                                        (userBalance / BigInt(Math.pow(10, selectedToken.decimals))).toString() : '-'} ${selectedToken.symbol} {!userBalance ?
                                                                <Typography variant="overline"
                                                                            sx={{ml: 2, color: '#ff007a'}}>⚠️
                                                                    No
                                                                    balance detected</Typography> : ''}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} // Full width on extra-small screens
                                                        sm={6}  // 2 columns on small screens (6/12)
                                                        md={4}  // 3 columns on medium screens (4/12)
                                                        lg={3}  // 4 columns on large screens (3/12)
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: 0,
                                                                backgroundColor: 'transparent',
                                                                borderRadius: 1
                                                            }}
                                                        >
                                                            <Typography variant="h6" sx={{fontWeight: 'bold', mb: 1}}>
                                                                Market Cap
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '0.85rem',
                                                                color: '#9c9b93',
                                                                ml: 0
                                                            }}>
                                                                ${shortNumber(selectedToken.mc)}<br/>
                                                                Price: ${selectedToken.priceusd}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} // Full width on extra-small screens
                                                        sm={6}  // 2 columns on small screens (6/12)
                                                        md={4}  // 3 columns on medium screens (4/12)
                                                        lg={3}  // 4 columns on large screens (3/12)
                                                    >
                                                        <Box
                                                            sx={{
                                                                padding: 0,
                                                                backgroundColor: 'transparent',
                                                                borderRadius: 1
                                                            }}
                                                        >
                                                            <Typography variant="h6" sx={{fontWeight: 'bold', mb: 1}}>
                                                                Worth
                                                            </Typography>
                                                            <Typography sx={{
                                                                fontSize: '0.85rem',
                                                                color: '#9c9b93',
                                                                ml: 0
                                                            }}>
                                                                {userBalanceWorth && userBalanceWorth !== '-' && userBalanceWorth !== '▒' ? `$${userBalanceWorth}` : '-'}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Box>
                                </>)}
                        </Box>

                        {authStatus === 'authenticated' && (
                            <>
                                {selectedToken && (
                                    <Box
                                        style={{
                                            backgroundColor: 'transparent',
                                            padding: '10px 20px',
                                            paddingBottom: '20px',
                                            borderRadius: '16px',
                                            position: 'relative',
                                            marginLeft: '10px',
                                            marginRight: '10px'
                                        }}
                                        className="newLockBg"
                                    >
                                        <Box>
                                            <TokenBalanceSlider
                                                balance={userBalance ? BigInt(userBalance) : BigInt(0)}
                                                sliderValue={balancePercentage}
                                                decimals={decimals}
                                                onChange={handleSliderChange}/>
                                        </Box>
                                    </Box>
                                )}
                            </>)}
                        {authStatus === 'authenticated' && (
                            <>
                                {selectedToken && (
                                    <>

                                        <Box
                                            style={{
                                                backgroundColor: 'transparent',
                                                padding: '10px 20px',
                                                paddingBottom: '10px',
                                                borderRadius: '16px',
                                                position: 'relative',
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}
                                            className="newLockBg"
                                        >
                                            {/* Time Slider */}
                                            <Box display="flex" alignItems="start" my={2}>
                                                <Looks3Icon fontSize="large" style={{color: '#ffffff'}}/>
                                                <Typography variant="h6"
                                                            style={{color: '#ffffff', marginLeft: '10px'}}>
                                                    Set the time or price conditions for unlocking
                                                </Typography>
                                            </Box>
                                            <LockTimeSlider value={lockTime}
                                                            onChange={(value) => setLockTime(value)}/>
                                            <PriceCapSlider
                                                price={Number(selectedToken.priceusd)}
                                                marketCap={Number(selectedToken.mc)}
                                                sliderValue={priceMultiplier}
                                                tokensToLock={tokensToLock}
                                                timeToLock={lockTime}
                                                onChange={(value) => setPriceMultiplier(Number(value.toFixed(2)))}
                                            />
                                        </Box>
                                    </>)}
                            </>)}
                        {authStatus === 'authenticated' && (
                            <>
                                {selectedToken && (
                                    <>
                                        <FormControlLabel
                                            sx={{
                                                marginLeft: '20px',
                                                marginTop: '20px',
                                                '& .MuiFormControlLabel-label': {
                                                    fontWeight: 'normal', // Font weight of the label
                                                    color: '#fff',  // Color of the label text
                                                    fontSize: '1.2rem'
                                                },
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={emergencyWithdrawEnabled}
                                                    onChange={(e) => setEmergencyWithdrawEnabled(e.target.checked)}
                                                    name="emergencyWithdraw"
                                                    color="primary"
                                                    sx={{
                                                        color: '#ff007a', // Checkbox color when unchecked
                                                        '&.Mui-checked': {
                                                            color: '#ff007a', // Checkbox color when checked
                                                        },
                                                        '&.MuiCheckbox-root': {
                                                            color: '#ff007a', // Checkbox root color
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Enable emergency withdrawal option"
                                        />
                                        <Typography variant="body1" sx={{
                                            marginLeft: '30px',
                                            marginTop: '0px',
                                            color: '#9c9b93'
                                        }}>Enabling this option lets you unlock your tokens anytime, but if you
                                            use <b className="pink">emergency withdrawal, a 50% fee</b> will
                                            apply.
                                            Otherwise, the standard 2% fee applies.</Typography>


                                        {/* Lock Now Button */}
                                        {isLockNowVisible && (
                                            <Box display="flex" justifyContent="center" my={2} mt={6}>
                                                <Button variant="contained" onClick={handleLock}
                                                        className={"mainButton"}>
                                                    {loading ? 'Locking Tokens...' : 'Lock Now'}
                                                </Button>
                                            </Box>
                                        )}

                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

            </Box>

            {/* Success Popup */
            }
            <Dialog
                open={showSuccess}
                onClose={handleCloseSuccess}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#1f1f22', // Dark background color
                        color: '#ffffff', // Text color
                    }
                }}
            >
                <DialogTitle sx={{color: '#ff007a'}}>Lock Successful</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: '#ffffff'}}>
                        Your tokens have been successfully locked!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseSuccess}
                        sx={{
                            background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
                            border: "1px solid #252527",
                            boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                            borderRadius: '37px',
                            minHeight: '40px',
                            padding: '5px 30px',
                            color: '#fff',
                            fontWeight: '900',
                            '&:hover': {
                                backgroundColor: '#d6005a', // Slightly darker on hover
                            }
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Lock Now Popup */
            }
            <Dialog
                open={showPopup}
                onClose={handleClosePopup}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#1f1f22', // Dark background color
                        color: '#ffffff', // Text color
                    }
                }}
            >
                <DialogTitle sx={{color: '#ff007a'}}>Approval and Lock</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: '#ffffff'}}>
                        <Box display="flex" alignItems="start" my={2}>
                            <LooksOneIcon fontSize="large" style={{color: '#ffffff'}}/>
                            <Typography variant="h6"
                                        style={{color: '#ffffff', marginLeft: '10px'}}>
                                Approve your tokens for transfer
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="start" my={2}>
                            <LooksTwoIcon fontSize="large" style={{
                                color: approvedLockHash && approvedLockHash === lockData?.hash ? '#ffffff' : 'rgb(121,134,134)',
                            }}/>
                            <Typography variant="h6"
                                        style={{
                                            color: approvedLockHash && approvedLockHash === lockData?.hash ? '#ffffff' : 'rgb(121,134,134)',
                                            marginLeft: '10px'
                                        }}>
                                Lock tokens
                            </Typography>
                        </Box>
                        You will need to approve your tokens for transfer, followed by locking them. Follow your wallet
                        confirmations.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClosePopup}
                        sx={{
                            background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
                            border: "1px solid #252527",
                            boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                            borderRadius: '37px',
                            minHeight: '40px',
                            padding: '5px 30px',
                            color: '#ffffff',
                            fontWeight: '900',
                            '&:hover': {
                                backgroundColor: '#d6005a', // Darken button on hover
                            }
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Snackbar */
            }
            <Snackbar
                open={!!showError}
                autoHideDuration={6000}
                onClose={handleCloseError}
                message={showError}
            />
        </>
    )
        ;
};

export default CenterModule;
