import React from "react";

interface MiniHeaderProps {
    children: React.ReactNode; // Accept any valid React nodes as children
}

const MiniHeader: React.FC<MiniHeaderProps> = ({children}) => {
    return (
        <div
            style={{
                backgroundColor: "rgba(255, 0, 122, 0.1)", // Light pink background
                border: "1px solid #252527", // Pink border
                color: "rgba(255,255,255, 0.8)", // Bright pink text color
                padding: "8px 16px", // Padding for spacing
                borderRadius: "32px", // Rounded corners
                fontWeight: "normal", // Bold text
                textAlign: "center", // Center the content
                margin: "10px auto", // Center horizontally in the parent
                width: "fit-content",
                fontSize: "0.9rem",
                display: "flex", // Enable flexbox
                alignItems: "center", // Center items vertically
            }}
        >
            {children}
        </div>
    );
};

export default MiniHeader;
