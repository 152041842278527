import {ConnectButton} from '@rainbow-me/rainbowkit';
import {Button, styled, Typography} from "@mui/material";
import React from "react";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

const StyledConnectButton = styled((props: any & { component?: React.ElementType }) => (
    <Button {...props} />
))(({theme}) => ({
    // backgroundColor: '#2a053c',
    background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
    border: "1px solid #252527",
    boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
    borderRadius: "37px",
    color: '#fff',
    textTransform: 'none',
    padding: '8px 16px',
    // '&:hover': {
    //   backgroundColor: '#343435',
    // },
    marginRight: '10px',
    // fontWeight: 'bold',
    '&.Mui-disabled': {
        color: '#ffffff'
    }
}));

export const CustomConnectButton = () => {
    return (
        <ConnectButton.Custom>
            {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
              }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                        authenticationStatus === 'authenticated');
                return (
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return (
                                    <StyledConnectButton onClick={openConnectModal} type="button"
                                        sx={{ fontWeight: "bold" }}>
                                        Connect Wallet<AccountBalanceWalletRoundedIcon
                                        sx={{
                                            fontSize: "18px",
                                            color: '#ffffff',
                                            backgroundColor: "transparent",
                                            paddingLeft: "8px",
                                            marginRight: "4px"
                                        }}
                                    />
                                    </StyledConnectButton>
                                );
                            }
                            if (chain.unsupported) {
                                return (
                                    <StyledConnectButton onClick={openChainModal} type="button">
                                        Wrong network
                                    </StyledConnectButton>
                                );
                            }
                            return (
                                <div style={{display: 'flex', gap: 12}}>
                                    {/*<StyledConnectButton*/}
                                    {/*    onClick={openChainModal}*/}
                                    {/*    style={{display: 'flex', alignItems: 'center'}}*/}
                                    {/*    type="button"*/}
                                    {/*>*/}
                                    {/*    {chain.hasIcon && (*/}
                                    {/*        <div*/}
                                    {/*            style={{*/}
                                    {/*                background: chain.iconBackground,*/}
                                    {/*                width: 12,*/}
                                    {/*                height: 12,*/}
                                    {/*                borderRadius: 999,*/}
                                    {/*                overflow: 'hidden',*/}
                                    {/*                marginRight: 4,*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            {chain.iconUrl && (*/}
                                    {/*                <img*/}
                                    {/*                    alt={chain.name ?? 'Chain icon'}*/}
                                    {/*                    src={chain.iconUrl}*/}
                                    {/*                    style={{width: 12, height: 12}}*/}
                                    {/*                />*/}
                                    {/*            )}*/}
                                    {/*        </div>*/}
                                    {/*    )}*/}
                                    {/*    {chain.name}*/}
                                    {/*</StyledConnectButton>*/}
                                    <StyledConnectButton onClick={openAccountModal} type="button" sx={{ fontWeight: "bold" }}>
                                        <Typography sx={{ display: {md: "none", lg: "inline"}, fontSize: "0.8rem", fontWeight: "bold" }}>{account.displayName}</Typography><AccountBalanceWalletRoundedIcon
                                        sx={{
                                            fontSize: "18px",
                                            color: '#ffffff',
                                            backgroundColor: "transparent",
                                            paddingLeft: "8px",
                                            marginRight: "4px"
                                        }}
                                    />
                                    </StyledConnectButton>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
};