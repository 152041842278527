import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, IconButton} from '@mui/material';
import {AppProps} from "../Shared";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import {useAccount} from "wagmi";

const handleCopy = (token: string) => {
    navigator.clipboard.writeText(token);
};

const CenterModuleReferrals: React.FC<AppProps> = ({authStatus}) => {

    const {isConnected, address} = useAccount();
    const [refid, setRefid] = useState<string | null>(null);
    const [referrals, setReferrals] = useState<number>(0);

    const fetchRefInfo = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/refinfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.refinfo.refid) {
                setRefid(data.refinfo.refid);
            }
            if (data.refinfo.referrals) {
                setReferrals(data.refinfo.referrals);
            }
        } catch (error) {
            console.error('Error fetching refInfo:', error);
        }
    };

    useEffect(() => {
        if (address && isConnected && authStatus === "authenticated") {
            fetchRefInfo();
        }
    }, [authStatus]);

    useEffect(() => {
        if (address && isConnected && authStatus === "authenticated") {
            fetchRefInfo();
        }
    }, []);

    if (!isConnected || !address || authStatus !== "authenticated") {
        return (
            <Box mt={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4"
                                    style={{
                                        color: '#fff',
                                        marginLeft: '20px',
                                        marginBottom: '20px',
                                        fontWeight: '900'
                                    }}>
                            Referrals
                        </Typography>
                        <Box display="flex" alignItems="start" my={2} sx={{marginLeft: '20px'}}>
                            <PeopleAltRoundedIcon fontSize="large" style={{color: '#fff', opacity: '1'}}/>
                            <Typography variant="h6"
                                        style={{color: '#fff', marginLeft: '10px', opacity: '1'}}>
                                Please connect your wallet to view your referral link
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box mt={4}>
            <Grid container spacing={2}>
                {/* 8-column section #1f1f22 #3a2d40 */}
                <Grid item xs={12} md={12}>
                    <Typography variant="h4"
                                style={{color: '#fff', marginLeft: '20px', marginBottom: '20px', fontWeight: '900'}}>
                        Referrals <span className="pink">earn 1% from your referrals unlocks</span>
                    </Typography>
                    <Box style={{
                        backgroundColor: '#150C10',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        border: '1px solid rgba(44, 44, 46, 0.5)',
                        borderRadius: '37px',
                        width: 'auto',
                        marginRight: '34px'
                    }}>
                        <Box display="flex" alignItems="start" my={2} sx={{marginLeft: '20px'}}>
                            <PeopleAltRoundedIcon fontSize="large" style={{color: '#fff', opacity: '1'}}/>
                            <Typography variant="h6"
                                        style={{color: '#fff', marginLeft: '10px', opacity: '1'}}>
                                Your referral link {refid && (
                                <>
                                    <IconButton onClick={() => handleCopy('https://moonordust.io/?refid=' + refid)}
                                                style={{
                                                    color: 'rgba(255, 255, 255, 0.8)',
                                                    marginLeft: '5px'
                                                }}>
                                        <ContentCopyIcon sx={{fontSize: '0.8rem', padding: '4px'}}/>
                                    </IconButton>
                                </>)}
                                {refid && (<pre>https://moonordust.io/?refid={refid}</pre>)}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="start" my={2} sx={{marginLeft: '20px'}}>
                            <GroupsRoundedIcon fontSize="large" style={{color: '#fff', opacity: '1'}}/>
                            <Typography variant="h6"
                                        style={{color: '#fff', marginLeft: '10px', opacity: '1'}}>
                                Referrals: <span className="pink">{referrals}</span>
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="start" my={2} sx={{marginLeft: '20px'}}>
                            <AccountBalanceRoundedIcon fontSize="large" style={{color: '#fff', opacity: '1'}}/>
                            <Typography variant="h6"
                                        style={{color: '#fff', marginLeft: '10px', opacity: '1'}}>
                                You can claim: <span className="pink">0.00 ETH</span>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CenterModuleReferrals;
