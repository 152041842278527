import React from 'react';
import {Box, Container} from '@mui/material';

const Section2: React.FC = () => {
    return (
        <Container>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    minHeight: '800px', // Full viewport height
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                {/* Background Image */}
                <Box
                    component="img"
                    src="/screen.png"
                    alt="Screen"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                />

                {/* Gradient Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'linear-gradient(to bottom, transparent 50%, #0b0709)',
                        zIndex: 2,
                    }}
                />
            </Box>
        </Container>
    );
};

export default Section2;
