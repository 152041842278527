import React from 'react';
import {Box, Slider, Typography} from '@mui/material';
import {INFINITY_DAYS} from "../Shared";

interface LockTimeSliderProps {
    value: number;
    onChange: (value: number) => void;
}

const LockTimeSlider: React.FC<LockTimeSliderProps> = ({value, onChange}) => {
    const formatValue = (value: number) => {
        if (value === 0) {
            return 'Time lock: Inactive';
        } else if (value === INFINITY_DAYS) { // 1000 years (Infinity)
            return '∞ Infinity';
        } else if (value < 365) {
            return `${value} day${value === 1 ? '' : 's'}`;
        } else {
            const years = Math.floor(value / 365);
            return `${years} year${years > 1 ? 's' : ''}`;
        }
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const mappedValue = customValueMapper(newValue as number);
        onChange(mappedValue);
    };

    // Custom scale for the slider (maps the range [0-100] to [0-3650] days and then to Infinity)
    const customValueMapper = (value: number) => {
        if (value === 0) return 0; // Time lock inactive
        if (value > 98) return INFINITY_DAYS; // Time lock infinity (1000 years)

        if (value <= 30) return Math.floor(value); // Slow increase: 1-30 days
        if (value <= 80) return Math.floor(30 + (value - 30) * 6.7); // Moderate increase: 30-365 days
        if (value <= 98) return Math.floor(365 + (value - 80) * 182.5); // Fast increase: 1-10 years
        return INFINITY_DAYS; // Last step is Infinity (1000 years)
    };

    const inverseCustomValueMapper = (value: number) => {
        if (value === 0) return 0; // Time lock inactive
        if (value === INFINITY_DAYS) return 100; // Time lock infinity (1000 years)

        if (value <= 30) return value; // 1-30 days
        if (value <= 365) return (value - 30) / 6.7 + 30; // 30-365 days
        if (value <= 3650) return (value - 365) / 182.5 + 80; // 1-10 years
        return 100; // Infinity (100 years)
    };


    const renderMarkers = () => {
        const markers = [];
        for (let i = 0; i <= inverseCustomValueMapper(value); i += 5) {
            markers.push(
                <Box
                    key={i}
                    sx={{
                        position: 'absolute',
                        left: `${i}%`,
                        bottom: 0,
                        width: '1px',
                        height: '12px',
                        backgroundColor: '#ff007a',
                        transform: 'translateX(-1px)', // Center the line
                        transition: "opacity 0.5s ease-in-out",
                        opacity: 1
                    }}
                />
            );
        }
        return markers;
    };

    return (
        <Box mt={4} mb={2} pl={4} pr={5}>
            <Typography variant="h6" style={{color: '#ffffff', marginBottom: '10px'}}>
                {formatValue(value)}
            </Typography>
            <Slider
                value={inverseCustomValueMapper(value)}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => formatValue(customValueMapper(value))}
                min={0}
                max={100}
                marks={[
                    {value: 0, label: 'Inactive'},
                    {value: 30, label: '30 days'},
                    {value: 80, label: '1 year'},
                    {value: 98, label: '10 years'},
                ]}
                step={1}
                sx={{
                    color: '#ff007a', // Custom color for the slider
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#ff007a', // Custom thumb color
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#9c9b93', // Custom rail color
                    },
                    '& .MuiSlider-markLabel': {
                        color: '#ff007a', // Custom mark label color
                    },
                }}
            />
            {/* Render markers */}
            <Box
                sx={{
                    position: 'relative',
                    top: '50%',
                    left: 0,
                    right: 0,
                    height: '10px',
                    transform: 'translateY(10px)',
                }}
            >
                {renderMarkers()}
            </Box>
        </Box>
    );
};

export default LockTimeSlider;
