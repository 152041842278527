import React from "react";
import {Box, Grid, Typography, Container} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TokenIcon from "@mui/icons-material/AccountBalance";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import MiniHeader from "./MiniHeader";
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';

const HowItWorks = () => {
    const steps = [
        {
            icon: <AccountBalanceWalletIcon sx={{fontSize: "30px", color: "#FF007A"}}/>,
            title: "Easy Wallet Login",
            description: "Quickly and securely log in using your crypto wallet.",
        },
        {
            icon: <TokenIcon sx={{fontSize: "30px", color: "#FF007A"}}/>,
            title: "Choose Your Token",
            description: "Select any token directly from your connected wallet.",
        },
        {
            icon: <AccessTimeIcon sx={{fontSize: "30px", color: "#FF007A"}}/>,
            title: "Set Conditions",
            description: "Define the time or price conditions for your token lock.",
        },
        {
            icon: <EmojiEventsIcon sx={{fontSize: "30px", color: "#FF007A"}}/>,
            title: "Relax and Earn",
            description: "Sit back and enjoy the rewards of effortless holding.",
        },
    ];

    return (
        <Box sx={{textAlign: "center", py: 8, bgcolor: "transparent", pb: 20 }} id="howitworks">
            <MiniHeader><ReceiptLongRoundedIcon sx={{marginRight: "5px"}}/>How it works</MiniHeader>
            {/* Title and Subtitle */}
            <Typography variant="h3" sx={{fontWeight: "bold", mb: 2, mt: 4}}>
                Smart Token Locking
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontSize: {xs: "1rem", md: "1.25rem"},
                    marginBottom: 4,
                    color: "rgba(255, 255, 255, 0.6)",
                }}
            >
                A simple and secure way to lock tokens with time or price conditions.
            </Typography>

            {/* Steps Section */}
            <Container sx={{pt: 5}}>
                <Grid container spacing={4} justifyContent="center" sx={{
                                    gap: { xs: 12, md: 0 } }}>
                    {steps.map((step, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Box
                                sx={{
                                    position: "relative",
                                    background: "linear-gradient(180deg, rgba(2,0,0,0) 0%, rgba(18,11,15,1) 49%)",
                                    borderRadius: "16px",
                                    border: "1px solid #2C2C2E",
                                    textAlign: "center",
                                    padding: "40px 20px 20px", // Extra padding to fit icon positioning
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflow: "visible", // Ensures icon can be positioned outside the card
                                    transition: "box-shadow 0.3s ease",
                                    "&:hover": {
                                        boxShadow: "0px 4px 12px rgba(0,0,0,0.3)",
                                    },
                                }}
                            >
                                {/* Icon */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-30px", // 50% above the card
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        width: "60px",
                                        height: "60px",
                                        background: "linear-gradient(120deg, rgba(17,1,1,1) 0%, rgba(48,15,15,0.75) 50%, rgba(18,11,11,1) 100%)", // Icon background gradient
                                        border: "1px solid #2C2C2E", // Light border around the icon
                                        borderRadius: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {step.icon}
                                </Box>

                                {/* Title */}
                                <Typography
                                    variant="h6"
                                    sx={{fontWeight: "bold", mb: 1, mt: 3}}
                                >
                                    {step.title}
                                </Typography>

                                {/* Description */}
                                <Typography
                                    variant="body1"
                                    sx={{color: "rgba(255, 255, 255, 0.6)"}}
                                >
                                    {step.description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default HowItWorks;
