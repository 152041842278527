import React, {useState} from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography
} from '@mui/material';
import {Link} from 'react-router-dom';
import {useAccount} from 'wagmi';
import MenuIcon from "@mui/icons-material/Menu";
import {CustomConnectButton} from "./CustomConnectButton";
import {AppProps} from "../Shared";
import StyledButtonv2 from "./StyledButtonv2";

const TopNavv2: React.FC<AppProps> = ({authStatus}) => {
    const {isConnected} = useAccount();

    // State to track mobile nav open/close
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    // Toggle mobile nav
    const toggleMobileNav = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    return (
        <Box sx={{bgcolor: "transparent", color: "#ffffff"}}>
            <Container maxWidth={false}>
                <Grid
                    item
                    xs={6}
                    md={2}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {/* Desktop Connect Button */}
                    <Box display="flex" alignItems="center" ml={2} sx={{display: {xs: "none", md: "block"}}}>
                        {isConnected ? (
                            <CustomConnectButton/>
                        ) : (
                            <CustomConnectButton/>
                        )}
                    </Box>

                    {/* Mobile Burger Menu */}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={toggleMobileNav} // Toggle the mobile nav
                        sx={{display: {xs: "block", md: "none"}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Grid>
            </Container>

            {/* Mobile Navigation Drawer */}
            <Drawer
                anchor="right" // Opens from the right side
                open={isMobileNavOpen} // Controlled by state
                onClose={toggleMobileNav} // Close when clicking outside
            >
                <Box
                    sx={{
                        width: 250,
                        bgcolor: '#0b0709',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 2,
                        color: '#ffffff',
                    }}
                >
                    <List>
                        <ListItem component={Link} to="/" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/"} style={{width: '100%', gap: 10, margin: '0px auto', background: 'none', boxShadow: 'none'}}>Home</StyledButtonv2>
                        </ListItem>
                        <ListItem component={Link} to="/my-locks" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/my-locks"} style={{width: '100%', gap: 10, margin: '0px auto', background: 'none', boxShadow: 'none'}}>My Locks</StyledButtonv2>
                        </ListItem>
                        <ListItem component={Link} to="/new-lock" onClick={toggleMobileNav}>
                            <StyledButtonv2 to={"/new-lock"} style={{width: '100%', gap: 10, margin: '0px auto'}}>New Lock</StyledButtonv2>
                        </ListItem>
                    </List>

                    {/* Mobile Connect Button */}
                    <Box mt="auto" pt={2}>
                        {isConnected ? (
                            <CustomConnectButton/>
                        ) : (
                            <CustomConnectButton/>
                        )}
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
};

export default TopNavv2;
