import React from 'react';
import CenterModule from "../components/CenterModuleMyLocks";
import {Box, Container} from "@mui/material";
import {AppProps} from "../Shared";

const MyLocks: React.FC<AppProps> = ({ authStatus }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <CenterModule  authStatus={ authStatus } />
    </Box>
  );
};

export default MyLocks;
