import React, {useState} from 'react';
import {Box, Slider, TextField, Typography} from '@mui/material';
import {shortNumber} from "../Shared";

interface BalanceInfo {
    balance: bigint;          // User's token balance
    sliderValue: number;       // Current value of the slider (percentage)
    decimals: number;       // Current value of the slider (percentage)
    onChange: (value: number, custom: number) => void; // Callback function to update slider value
}

const TokenBalanceSlider: React.FC<BalanceInfo> = ({balance, sliderValue, decimals, onChange}) => {

    // Calculate the amount of tokens to lock based on the percentage value from the slider
    const getTokenAmount = (value: number) => {
        return (balance * BigInt(value)) / BigInt(100) / BigInt(Math.pow(10, decimals));
    };

    // Local state to manage the input value
    const [inputValue, setInputValue] = useState(getTokenAmount(100));

    const getSliderAmount = (value: number) => {
        // Calculate percentage value based on the amount of tokens to lock
        return (BigInt(value) * BigInt(100) * BigInt(Math.pow(10, decimals))) / balance;
    };

    // Handle slider change and pass the updated value to the parent component
    const handleSliderChange = (event: Event, newValue: number | number[]) => {

        const updatedValue = newValue as number;
        setInputValue(getTokenAmount(updatedValue)); // Update input value
        onChange(updatedValue, 0); // Update parent state
    };


    // Handle changes from the text input
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // Allow only numeric values
        if (/^\d*$/.test(value)) {
            const numericValue = BigInt(value);
            setInputValue(numericValue); // Update the input state
            let updatedSliderValue = getSliderAmount(Number(numericValue.toString()));

            // Update slider only if within valid range
            if (updatedSliderValue < 0) {
                updatedSliderValue = BigInt(0);
            }
            if (updatedSliderValue > 100) {
                updatedSliderValue = BigInt(100);
            }
            onChange(Number(updatedSliderValue.toString()), Number(numericValue.toString()));
        }
    };

    const renderMarkers = () => {
        const markers = [];
        for (let i = 0; i <= sliderValue; i += 5) {
            markers.push(
                <Box
                    key={i}
                    sx={{
                        position: 'absolute',
                        left: `${i}%`,
                        bottom: 0,
                        width: '1px',
                        height: '12px',
                        backgroundColor: '#ff007a',
                        transform: 'translateX(-1px)', // Center the line
                        transition: "opacity 0.5s ease-in-out",
                        opacity: 1
                    }}
                />
            );
        }
        return markers;
    };

    return (
        <Box mt={4} mb={6} pl={4} pr={5}>
            <Typography variant="h6"
                        style={{color: '#ffffff', marginBottom: '10px', display: 'flex', alignItems: 'baseline'}}>
                Tokens to lock: <TextField
                value={/*getTokenAmount(sliderValue).toString()*/inputValue.toString()}
                onChange={handleInputChange}
                inputProps={{
                    style: {color: '#ffffff'}, // Styling for the input text
                    inputMode: 'numeric', // Ensure numeric keyboard on mobile
                }}
                sx={{
                    marginLeft: '15px',
                    marginBottom: 2,
                    border: '1px solid #ff007a', // Custom border color
                    borderRadius: '16px',
                    input: {color: '#ffffff'}, // MUI-specific styling for text input
                    '& .MuiOutlinedInput-root': {

                        marginLeft: '5px',
                        '& input': {
                            padding: '10px 10px',
                            marginLeft: '5px'
                        },
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                }}
            />
            </Typography>

            <Slider
                value={sliderValue}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${shortNumber(getTokenAmount(value).toString())}`}
                min={1}
                max={100}
                marks={[
                    {value: 1, label: '1%'},
                    {value: 25, label: '25%'},
                    {value: 50, label: '50%'},
                    {value: 75, label: '75%'},
                    {value: 100, label: '100%'}
                ]}
                step={1}
                sx={{
                    color: '#ff007a', // Custom color for the slider
                    '& .MuiSlider-thumb': {
                        backgroundColor: '#ff007a', // Custom thumb color
                    },
                    '& .MuiSlider-rail': {
                        backgroundColor: '#9c9b93', // Custom rail color
                    },
                    '& .MuiSlider-markLabel': {
                        color: '#9c9b93', // Custom mark label color
                    },
                }}
            />

            {/* Render markers */}
            <Box
                sx={{
                    position: 'relative',
                    top: '50%',
                    left: 0,
                    right: 0,
                    height: '10px',
                    transform: 'translateY(10px)',
                }}
            >
                {renderMarkers()}
            </Box>
        </Box>
    );
};

export default TokenBalanceSlider;
