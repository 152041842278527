import BeatLoader from "react-spinners/BeatLoader";

const LoaderMod = () => {
    return (
        <>
            <BeatLoader
                color={ '#fff' }
                loading={ true }
                cssOverride={{
                    display: 'block',
                    margin: '0 auto'
                }}
            />
        </>
    );
};

export default LoaderMod;