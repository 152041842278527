import React from "react";
import {Link} from "react-router-dom"; // Import Link from react-router-dom

interface StyledButtonv2Props {
    children: React.ReactNode;
    to: string; // The 'to' prop is now mandatory for navigation
    onClick?: () => void; // Optional click handler
    style?: React.CSSProperties; // Optional style for customization
}

const StyledButtonv2: React.FC<StyledButtonv2Props> = ({children, to, onClick, style}) => {
    return (
        <Link
            to={to} // Required for Link to know where to navigate
            onClick={onClick} // Optional onClick handler
            style={{
                background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
                border: "1px solid #252527",
                boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                borderRadius: "37px",
                fontWeight: "bold",
                color: "rgba(255,255,255, 0.8)", // Text color
                padding: "12px 20px", // Padding
                textAlign: "center", // Center the text
                margin: "10px auto", // Center horizontally in the parent
                width: "fit-content",
                fontSize: "1.2rem",
                display: "flex", // Enable flexbox
                alignItems: "center", // Center items vertically
                justifyContent: "center", // Center items horizontally
                textDecoration: "none", // Remove underline
                cursor: onClick || to ? "pointer" : "default", // Pointer cursor if clickable
                ...style, // Allow overriding styles
            }}
        >
            {children}
        </Link>
    );
};

export default StyledButtonv2;
