import React from 'react';
import {Box} from '@mui/material';

const DashboardContainer: React.FC<{ children: React.ReactNode }> = ({children}) => {
    return (
        <Box
            style={{
                border: '1px solid rgba(44, 44, 46, 0.5)', // Border with 50% transparency
                borderRadius: '10px', // Rounded corners
                backgroundColor: '#150C10', // Background color
                padding: '10px', // Padding inside the container
                marginRight: '24px',
                marginBottom: '24px',
                flex: 1
            }}
        >
            {children}
        </Box>
    );
};

export default DashboardContainer;
