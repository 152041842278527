import React from 'react';
import CenterModule from "../components/CenterModuleMyLocks";
import {Box, Container} from "@mui/material";
import {AppProps} from "../Shared";
import CenterModuleReferrals from "../components/CenterModuleReferrals";

const Referrals: React.FC<AppProps> = ({authStatus}) => {
    return (
        <Box sx={{flex: 1}}>
            <CenterModuleReferrals authStatus={authStatus}/>
        </Box>
    );
};

export default Referrals;
