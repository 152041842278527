import React from 'react';
import {Box, List, ListItem, ListItemIcon, ListItemText, Button} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StyledButtonv2 from "./StyledButtonv2";
import DiamondAnimation from "./Diamond";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

const LeftNav: React.FC = () => {
    const navItems = [
        {text: 'My Locks', icon: <LockOpenRoundedIcon/>, link: '/my-locks'},
        {text: 'New Lock', icon: <AddCircleOutlineRoundedIcon/>, link: '/new-lock'},
        {text: 'Referrals', icon: <PeopleAltRoundedIcon/>, link: '/referrals'},
    ];

    const location = useLocation();

    const isActive = (path: string) => location.pathname === path;

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: {
                    xs: 'auto',
                    lg: '100vh'
                },
                padding: '20px', // Adds spacing around the content
            }}
        >
            {/* Navigation List */}
            <List style={{marginTop: '20px'}}>
                {navItems.map((item, index) => (
                    <ListItem
                        key={index}
                        component={Link}
                        to={item.link}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                            color: isActive(item.link) ? 'red' : 'rgba(255, 255, 255, 0.8)',
                            marginBottom: '10px', // Adds spacing between items
                        }}
                    >
                        {isActive(item.link) ? (
                            // Render StyledButtonv2 when isActive is true
                            <StyledButtonv2 to={item.link} style={{width: '100%', gap: 10, margin: '0px auto'}}>
                                {item.icon}{item.text}
                            </StyledButtonv2>
                        ) : (
                            // Default ListItemIcon and ListItemText
                            <>
                                <ListItemIcon
                                    style={{
                                        color: 'rgba(255, 255, 255, 0.8)',
                                        minWidth: '40px',
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text}/>
                            </>
                        )}
                    </ListItem>
                ))}
                <DiamondAnimation/>
            </List>

            {/* Logout Button */}
            <Box sx={{marginTop: 'auto', display: {xs: 'none', lg: 'block'}}}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        textTransform: 'none',
                        padding: '10px 20px',
                        color: 'rgba(255, 255, 255, 0.8)',
                        backgroundColor: 'transparent',
                    }}
                    startIcon={<PowerSettingsNewRoundedIcon/>}
                >
                    Logout
                </Button>
            </Box>
        </Box>
    );
};

export default LeftNav;
