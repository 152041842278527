import React from 'react';
import {AppBar, Box, Button, Container, Grid, IconButton, Toolbar, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {ConnectButton} from '@rainbow-me/rainbowkit';
import TopCenter from './TopCenter';
import TopNav from './TopNav';
import {useAccount} from 'wagmi';
import {AppProps} from "../Shared";
import MenuIcon from "@mui/icons-material/Menu";
import TopNavLanding from "./TopNavLanding";

const TopRowLanding: React.FC<AppProps> = ({authStatus}) => {
    const {isConnected} = useAccount();

    return (
        <Box sx={{bgcolor: "transparent", color: "#ffffff", mt: 2}}>
            <Container maxWidth="lg"> {/* Adjust container width as needed */}
                <AppBar
                    position="static"
                    sx={{
                        bgcolor: "transparent", // Makes AppBar inherit Container's width
                        boxShadow: "none", // Removes AppBar shadow
                    }}
                >
                    <Toolbar disableGutters sx={{ zIndex: "5" }}>
                        <Grid container alignItems="center">
                            {/* Left Column (Logo) */}
                            <Grid item xs={6} md={2}>
                                <Link to="/">
                                    <img
                                        src="/logowhite.png"
                                        alt="Moon or Dust"
                                        style={{marginLeft: '0px', maxHeight: '25px'}}
                                    />
                                </Link>
                            </Grid>

                            {/* Center Column (Top Nav) - Only visible on desktop */}
                            <TopNavLanding authStatus={authStatus} />

                        </Grid>
                    </Toolbar>
                </AppBar>
            </Container>
        </Box>
    );
};

export default TopRowLanding;
