import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    Snackbar,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton, LinearProgress, LinearProgressProps,
} from '@mui/material';
import {linearProgressClasses} from '@mui/material/LinearProgress';
import {useNavigate} from 'react-router-dom';
import {useAccount} from 'wagmi';
import {useWeb3jsSigner} from './useWeb3js';
import {AppProps, INFINITY_DAYS, LOCKER_ABI, shortNumber} from "../Shared";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BarChartIcon from '@mui/icons-material/BarChart';
import moment from 'moment';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import DiamondRoundedIcon from '@mui/icons-material/DiamondRounded';
import StyledButtonv2 from "./StyledButtonv2";
import Loader from './Loader';
import PunchClockRoundedIcon from '@mui/icons-material/PunchClockRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface LockData {
    tokenName: string;
    token: string;
    conditions: string;
    status: string;
    lockId: string;
    tokenData: any;
    _id: string;
    tokensLocked: number;
    lockTime: number;
    priceMultiplier: number;
    emergencyWithdrawEnabled?: boolean;
    multiplier: number;
    amount: string;
    unlockPrice?: number;
    unlockPriceTimestamp?: string;
    unlockReason?: string;
    unlockTimestamp?: string;
    userUnlockReason?: string;
    userUnlockTimestamp?: string;
    currentValue?: string;
    destinationValue?: string;
    mcToday: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>

            <AccessTimeRoundedIcon sx={{
                display: 'inline-block',
                fontSize: '0.8rem',
                marginRight: '5px'
            }}/>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" sx={{
                    borderRadius: 5,
                    height: '10px',
                    backgroundColor: '#360b1f',
                    [`& .${linearProgressClasses.bar}`]: {
                        background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0.3) 100%)",
                        boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                    }
                }} {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography
                    variant="body2"
                    sx={{color: '#9c9b93', fontSize: '0.6rem'}}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function LinearProgressWithLabelPrice(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '5px'}}>

            <MonetizationOnRoundedIcon sx={{
                display: 'inline-block',
                fontSize: '0.8rem',
                marginRight: '5px'
            }}/>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" sx={{
                    borderRadius: 5,
                    height: '10px',
                    backgroundColor: '#360b1f',
                    [`& .${linearProgressClasses.bar}`]: {
                        background: "linear-gradient(0deg, rgba(0, 240, 40, 0.2), rgba(0, 240, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #01FF7A 0%, rgba(0, 250, 22, 0.3) 100%)",
                        boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(0, 255, 122, 0.5)",
                    }
                }} {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography
                    variant="body2"
                    sx={{color: '#9c9b93', fontSize: '0.6rem'}}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

// Helper function to check if lock time is in the past and format time
const formatTimeRemaining = (lockTime: number) => {
    const now = moment();
    const unlockTime = moment.unix(lockTime);

    if (now.isAfter(unlockTime)) {
        const duration = moment.duration(now.diff(unlockTime));
        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.asMonths()) % 12;  // Months after subtracting years
        const days = Math.floor(duration.asDays()) % 30;  // Days after subtracting years and months
        const hours = duration.hours();
        const minutes = duration.minutes();

        if (years > 0) return `${years} year(s) ago`;
        if (months > 0) return `${months} month(s) ago`;
        if (days > 0) return `${days} day(s) ago`;
        if (hours > 0) return `${hours} hour(s) ago`;
        return `${minutes} minute(s) ago`;
    }

    // Get the time remaining for unlock
    const duration = moment.duration(unlockTime.diff(now));
    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths()) % 12;
    const days = Math.floor(duration.asDays()) % 30;
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) return `in ${years} year(s)`;
    if (months > 0) return `in ${months} month(s)`;
    if (days > 0) return `in ${days} day(s)`;
    if (hours > 0) return `in ${hours} hour(s)`;
    return `in ${minutes} minute(s)`;
};

function calculatePriceProgress(
    startingPrice: number,
    currentPrice: number,
    destinationPrice: number
): number {
    // Prevent division by zero
    if (destinationPrice === startingPrice) {
        return 100;
    }

    // Calculate progress
    const progress = ((currentPrice - startingPrice) / (destinationPrice - startingPrice)) * 100;

    // Clamp progress between 0% and 100%
    return Math.max(0, Math.min(100, progress));
}

function calculateTimeProgress(
    startTime: Date,
    todayDate: Date,
    destinationTime: Date
): number {
    const startMs = startTime.getTime(); // Convert to milliseconds
    const todayMs = todayDate.getTime();
    const destinationMs = destinationTime.getTime();

    // Prevent division by zero
    if (destinationMs === startMs) {
        return 100;
    }

    // Calculate progress
    const progress = ((todayMs - startMs) / (destinationMs - startMs)) * 100;

    // Clamp progress between 0% and 100%
    return Math.max(0, Math.min(100, progress));
}

const generateLockProgressBars = (lock: any) => {
    const currentTime = moment().unix();
    const lockTimeRemaining = currentTime < lock.lockTime && lock.lockTime !== 0;
    const priceConditionMet = Number(lock.tokenData.priceusd) >= (Number(lock.priceToday) * lock.multiplier);
    const lockedTokens: any = BigInt(lock.amount) / BigInt(Math.pow(10, lock.tokenData.decimals));
    const isLocked: boolean = lock.status === 'locked';
    const isUnlockable: boolean = lock.status === 'unlockable';
    const isUnlocked: boolean = lock.status === 'unlocked';
    const lockTime = Number(lock.lockTime);
    const isInfinity = INFINITY_DAYS * 60 * 60 * 24 === lockTime;

    /*
        <LinearProgressWithLabel value={100}/>
        <LinearProgressWithLabelPrice value={100}/>
     */

    // 1.
    if (isLocked && (lockTime === 0 || isInfinity) && lock.multiplier > 0) {
        const priceChange = calculatePriceProgress(Number(lock.priceToday), Number(lock.tokenData.priceusd), Number(lock.priceToday) * lock.multiplier);
        return (
            <>
                <LinearProgressWithLabelPrice value={priceChange}/>
            </>
        );
    }
    // 2.
    if (isLocked && lock.multiplier === 0 && lockTime === 0) {
        return (
            <>
                <LinearProgressWithLabel value={100}/>
            </>
        );
    }
    // 3.
    if (isLocked && lock.multiplier === 0 && isInfinity) {
        return (
            <>
                <LinearProgressWithLabel value={0}/>
            </>
        );
    }
    // 4.
    if (isUnlockable && lock.unlockReason === 'price') {
        return (
            <>
                <LinearProgressWithLabelPrice value={100}/>
            </>
        );
    }
    // 5.
    if (isUnlockable && lock.unlockReason === 'time') {
        return (
            <>
                <LinearProgressWithLabel value={100}/>
            </>
        );
    }
    // 6.
    if (isUnlocked && lock.userUnlockReason === 'emergency') {
        return (
            <>

            </>
        );
    }
    // 7.
    if (isUnlocked && lock.userUnlockReason === 'user') {
        const priceChange = calculatePriceProgress(Number(lock.priceToday), Number(lock.tokenData.priceusd), Number(lock.priceToday) * lock.multiplier);
        return (
            <>
                <LinearProgressWithLabelPrice value={priceChange}/>
            </>
        );
    }
    // 8.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier === 0) {
        const timeChange = calculateTimeProgress(new Date(Number(lock.created)), new Date(), new Date(lockTime * 1000));
        return (
            <>
                <LinearProgressWithLabel value={timeChange}/>
            </>
        );
    }
    // 9.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier > 0) {
        const timeChange = calculateTimeProgress(new Date(Number(lock.created)), new Date(), new Date(lockTime * 1000));
        const priceChange = calculatePriceProgress(Number(lock.priceToday), Number(lock.tokenData.priceusd), Number(lock.priceToday) * lock.multiplier);
        return (
            <>
                <LinearProgressWithLabel value={timeChange}/>
                <LinearProgressWithLabelPrice value={priceChange}/>
            </>
        );
    }


}

// Function to generate lock status wording
const generateLockStatus = (lock: any) => {
    const currentTime = moment().unix();
    const lockTimeRemaining = currentTime < lock.lockTime && lock.lockTime !== 0;
    const priceConditionMet = Number(lock.tokenData.priceusd) >= (Number(lock.priceToday) * lock.multiplier);
    const lockedTokens: any = BigInt(lock.amount) / BigInt(Math.pow(10, lock.tokenData.decimals));
    const isLocked: boolean = lock.status === 'locked';
    const isUnlockable: boolean = lock.status === 'unlockable';
    const isUnlocked: boolean = lock.status === 'unlocked';
    const lockTime = Number(lock.lockTime);
    const isInfinity = INFINITY_DAYS * 60 * 60 * 24 === lockTime;
    const lockTxExplorer = `https://etherscan.io/tx/${lock.lockTxHash}`;

    /*
    Scenarios:
        1. status = locked, timeLock = 0 or infinity, price conditions not met
        2. status = locked, timeLock = 0, price conditions inactive, unlockable anytime
        3. status = locked, timeLock = infinity, price conditions inactive, never unlockable
        4. status = unlockable, unlock reason price
        5. status = unlockable, unlock reason time
        6. status = unlocked, unlock reason emergency
        7. status = unlocked, unlock reason user
        8. status = locked, timeLock = specific, price conditions inactive, time remaining
        9. status = locked, timeLock = specific, price conditions specific, time remaining
     */

    // Display "+XX% since lock", if price increased make it green, if decreased make it red
    function calculatePriceComparison(currentPrice: number, lockedPrice: number) {
        const priceChange = ((currentPrice - lockedPrice) / lockedPrice) * 100;
        return (priceChange > 0 ? '+' : '') + priceChange.toFixed(2) + '%';
    }

    const priceChange = calculatePriceComparison(Number(lock.tokenData.priceusd), Number(lock.priceToday));
    const priceChangeColor = priceChange.includes('-') ? 'red' : 'green';
    const priceChangeMsg = <span style={{color: priceChangeColor, fontWeight: '900'}}>{priceChange}</span>;

    // 1.
    if (isLocked && (lockTime === 0 || isInfinity) && lock.multiplier > 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">⭐️ Tokens will be available to unlock when token's price
                reaches <b>${Number(lock.priceToday) * lock.multiplier}</b>
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock)
                MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                    {
                        lock.currentValue ? (
                            <span>📈 Current value is <b>${lock.currentValue}</b></span>
                        ) : ''
                    }
                    {
                        lock.destinationValue ? (
                            <span
                                style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                        ) : ''
                    }
                </span>
            </>
        );
    }
    // 2.
    if (isLocked && lock.multiplier === 0 && lockTime === 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">⭐️ Tokens will be available to unlock shortly, refresh this page in few moments</span>
            </>
        );
    }
    // 3.
    if (isLocked && lock.multiplier === 0 && isInfinity) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">{lock.emergencyWithdrawEnabled ? '🔐' : '🔒'} Tokens are locked
                    forever{lock.emergencyWithdrawEnabled ? ' untill emergency withdrawal is used' : ' with emergency withdrawal disabled'}</span>
            </>
        );
    }
    // 4.
    if (isUnlockable && lock.unlockReason === 'price') {
        const unlockPriceDate = new Date(Number(lock.unlockPriceTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">🟢 Tokens can be unlocked now because the token's price reached
                ${(Number(lock.priceToday) * lock.multiplier)} on {unlockPriceDate}
                    <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock)
                MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                    {
                        lock.currentValue ? (
                            <span>📈 Current value is <b>${lock.currentValue}</b></span>
                        ) : ''
                    }
                    {
                        lock.destinationValue ? (
                            <span
                                style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                        ) : ''
                    }</span>
            </>
        );
    }
    // 5.
    if (isUnlockable && lock.unlockReason === 'time') {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">🟢 Tokens can be unlocked now because the the lock time has passed
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock)
                MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                    {
                        lock.currentValue ? (
                            <span>📈 Current value is <b>${lock.currentValue}</b></span>
                        ) : ''
                    }
                    {
                        lock.destinationValue ? (
                            <span
                                style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                        ) : ''
                    }</span>
            </>
        );
    }
    // 6.
    if (isUnlocked && lock.userUnlockReason === 'emergency') {
        const unlockTxExplorer = `https://etherscan.io/tx/${lock.userUnlockTxHash}`;
        const userUnlockDate = new Date(Number(lock.userUnlockTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">✅ Tokens were unlocked with Emergency Withdraw on {userUnlockDate} <a
                    href={unlockTxExplorer}
                    target="_blank"
                    rel="noreferrer"><OpenInNewRoundedIcon
                    sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                </span>
            </>
        );
    }
    // 7.
    if (isUnlocked && lock.userUnlockReason === 'user') {
        const unlockTxExplorer = `https://etherscan.io/tx/${lock.userUnlockTxHash}`;
        const userUnlockDate = new Date(Number(lock.userUnlockTimestamp)).toLocaleString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">✅ Tokens were unlocked on {userUnlockDate} <a href={unlockTxExplorer}
                                                                                                target="_blank"
                                                                                                rel="noreferrer"><OpenInNewRoundedIcon
                    sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a></span>
            </>
        );
    }
    // 8.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier === 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span className="conditionsSmall">⭐️ Tokens will be available to unlock {formatTimeRemaining(lockTime)}
                    <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock)
                MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                    {
                        lock.currentValue ? (
                            <span>📈 Current value is <b>${lock.currentValue}</b></span>
                        ) : ''
                    }
                    {
                        lock.destinationValue ? (
                            <span
                                style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                        ) : ''
                    }</span>
            </>
        );
    }
    // 9.
    if (isLocked && (lockTime !== 0 && !isInfinity) && lock.multiplier > 0) {
        return (
            <>
                💎 Locked <b>{lockedTokens.toString()}</b> {lock.tokenData.symbol} <a href={lockTxExplorer}
                                                                                     target="_blank"
                                                                                     rel="noreferrer"><OpenInNewRoundedIcon
                sx={{fontSize: "14px", color: "#ccc", marginLeft: "5px"}}/></a>
                <br/>
                <span
                    className="conditionsSmall">⭐️ Tokens will be available to unlock {formatTimeRemaining(lockTime)} or when price
                reaches <b>${Number(lock.priceToday) * lock.multiplier}</b>
                <br/>
                💰 Current price is <b>${lock.tokenData.priceusd}</b> ({priceChangeMsg} since lock)
                MC <b>${shortNumber(lock.tokenData.mc)}</b>
                <br/>
                    {
                        lock.currentValue ? (
                            <span>📈 Current value is <b>${lock.currentValue}</b></span>
                        ) : ''
                    }
                    {
                        lock.destinationValue ? (
                            <span
                                style={{marginLeft: "10px"}}>🎯 Destination value is <b>${lock.destinationValue}</b></span>
                        ) : ''
                    }</span>
            </>
        );
    }
};

const MyLocks: React.FC<AppProps> = ({authStatus}) => {
    const {address, isConnected} = useAccount();
    const web3jsSigner = useWeb3jsSigner();
    const [locks, setLocks] = useState<LockData[]>([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState<string | null>(null);
    const [selectedLockId, setSelectedLockId] = useState<string | null>(null);
    const [filterType, setFilterType] = useState<string>('active');
    const [locksLoading, setLocksLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const fetchControllerRef = useRef<AbortController | null>(null);

    // Fetch user's locks from the backend
    const fetchLocks = async (type: string = 'active') => {
        setLocks([]);
        setLocksLoading(true);
        if (fetchControllerRef.current) {
            fetchControllerRef.current.abort();
        }
        const controller = new AbortController();
        fetchControllerRef.current = controller;
        try {
            if (authStatus === 'authenticated') {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-locks?type=' + type, {
                    method: 'GET',
                    credentials: 'include',
                });
                if (controller.signal.aborted) {
                    return null;
                }
                const data = await response.json();
                setLocks(data.locks);
            }
        } catch (error) {
            console.error('Error fetching locks:', error);
            setLocks([]);
        }
        setLocksLoading(false);
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (address && isConnected && authStatus === "authenticated") {
            fetchLocks(filterType); // Initial fetch
            // @ts-ignore
            intervalId = setInterval(() => {
                fetchLocks(filterType);
            }, 120000);
        } else {
            setLocks([]); // Clear locks if not authenticated or disconnected
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Clean up the interval when component unmounts
            }

            if (fetchControllerRef.current) {
                fetchControllerRef.current.abort();
            }
        };
    }, [authStatus, filterType]);

    const updateLockBackend = async (lockDetails: any) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/lock-update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(lockDetails),
                credentials: 'include'
            });

            const data = await response.json();
            if (data.success) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    };

    // Handle unlock action
    const handleUnlock = async (lockId: string) => {
        try {
            let unlockHash: any;
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-lock-emergency-hash', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({lockId})
                });
                const data = await response.json();

                if (data && data.emergencyHash) {
                    unlockHash = data.emergencyHash;
                }
            } catch (error) {
                console.error('Error fetching locks:', error);
            }

            if (!unlockHash) {
                throw new Error("Error fetching unlock data, try again later or contact support.");
            }
            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;
            const lockerContract = new web3jsSigner.eth.Contract(LOCKER_ABI, lockerAddress);

            const unlockTransaction = lockerContract.methods.unlockTokens(lockId, unlockHash);
            const unlockTxHash: any = await unlockTransaction.send({
                from: address,
            });

            setShowSuccess(true);

            await updateLockBackend({
                newStatus: "unlocked",
                unlockReason: "user",
                unlockTxHash: unlockTxHash.transactionHash ? unlockTxHash.transactionHash : unlockTxHash,
                lockId
            });

            await fetchLocks(filterType);
        } catch (error) {
            console.error('Error unlocking tokens', error);
            setShowError('An error occurred while unlock. Try again later or contact support.');
        }
    };

    // Handle emergency withdraw action
    const handleEmergencyWithdraw = async (lockId: string) => {
        try {
            let emergencyHash: any;
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + '/api/user/my-lock-emergency-hash', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({lockId})
                });
                const data = await response.json();

                if (data && data.emergencyHash) {
                    emergencyHash = data.emergencyHash;
                }
            } catch (error) {
                console.error('Error fetching locks:', error);
            }

            if (!emergencyHash) {
                throw new Error("Error fetching emergency hash, try again later or contact support.");
            }

            const lockerAddress = process.env.REACT_APP_LOCKER_ADDRESS;
            const lockerContract = new web3jsSigner.eth.Contract(LOCKER_ABI, lockerAddress);

            const emergencyWithdrawTransaction = lockerContract.methods.emergencyWithdraw(lockId, emergencyHash);
            const unlockTxHash: any = await emergencyWithdrawTransaction.send({
                from: address,
            });

            setShowSuccess(true);

            await updateLockBackend({
                newStatus: "unlocked",
                unlockReason: "emergency",
                unlockTxHash: unlockTxHash.transactionHash ? unlockTxHash.transactionHash : unlockTxHash,
                lockId
            });

            await fetchLocks(filterType);
        } catch (error) {
            console.error('Error during emergency withdraw', error);
            setShowError('An error occurred during emergency withdrawal.');
        }
    };

    // Handle close of success popup
    const handleCloseSuccess = () => {
        setShowSuccess(false);
        navigate('/my-locks');
    };

    // Handle close of error notification
    const handleCloseError = () => {
        setShowError(null);
    };

    // Copy token address to clipboard
    const handleCopy = (token: string) => {
        navigator.clipboard.writeText(token);
    };

    const changeFilter = (filter: string) => {
        setFilterType(filter);
    }

    // Calculate comparison between destination and current price
    const calculatePriceComparison = (destinationPrice: number, currentPrice: number) => {
        const difference = ((destinationPrice - currentPrice) / currentPrice) * 100;
        return difference.toFixed(2) + '%';
    };

    return (
        <Box mt={4}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h4"
                                style={{color: '#fff', marginLeft: '20px', marginBottom: '20px', fontWeight: '900'}}>
                        My Locks
                    </Typography>

                    <TableContainer component={Paper}
                                    style={{
                                        backgroundColor: '#150C10',
                                        paddingLeft: '20px',
                                        paddingRight: '20px',
                                        border: '1px solid rgba(44, 44, 46, 0.5)',
                                        borderRadius: '37px',
                                        width: 'auto',
                                        marginRight: '34px'
                                    }}
                                    sx={{
                                        overflowX: 'visible', // Enable horizontal scrolling on smaller screens
                                        overflowY: 'visible'
                                    }}>


                        <Grid item xs={12} md={12} sx={{
                            color: '#fff',
                            marginLeft: '0px',
                            marginBottom: '20px',
                            marginTop: '0px',
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: 'space-between'
                        }}>

                            <Box sx={{
                                display: 'flex'
                            }}>
                                <Box sx={{
                                    padding: '4px 5px',
                                    border: '1px solid rgba(44, 44, 46, 0.5)',
                                    borderRadius: '50%',
                                    background: '#150c10',
                                    lineHeight: '0.8rem',
                                    marginRight: '4px'
                                }}><MonetizationOnOutlinedIcon
                                    sx={{margin: '0', fontSize: '1.2rem', lineHeight: '1.2rem'}}/></Box>
                                <Typography variant="body1"
                                            style={{
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '1.2rem'
                                            }}>Token
                                </Typography>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                backgroundColor: {
                                    xs: 'transparent',
                                    lg: '#0b0709'
                                },
                                border: {
                                    xs: '0x',
                                    lg: '0px'
                                },
                                borderTop: '0px',
                                borderRadius: '0px 0px 150px 150px',
                                position: 'relative',
                                top: {
                                    xs: '10px',
                                    lg: '-26px'
                                },
                                padding: {
                                    xs: '0px 10px 10px 60px',
                                    lg: '0px 60px 10px 60px'
                                },
                                alignItems: 'start',
                                gap: 2
                            }}>
                                <StyledButtonv2 to='#' style={{
                                    marginTop: '0px',
                                    borderRadius: '10px',
                                    color: filterType === 'active' ? '#fff' : 'rgba(255,255,255, 0.8)',
                                    background: filterType === 'active' ? "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)" : '#1e1619',
                                    boxShadow: filterType === 'active' ? "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)" : 'none',
                                    fontWeight: filterType === 'active' ? 'bold' : 'normal',
                                }}
                                                onClick={() => changeFilter('active')}>Active</StyledButtonv2>
                                <StyledButtonv2 to='#' style={{
                                    marginTop: '0px',
                                    borderRadius: '10px',
                                    color: filterType === 'all' ? '#fff' : 'rgba(255,255,255, 0.8)',
                                    background: filterType === 'all' ? "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)" : '#1e1619',
                                    boxShadow: filterType === 'all' ? "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)" : 'none',
                                    fontWeight: filterType === 'all' ? 'bold' : 'normal',
                                }}
                                                onClick={() => changeFilter('all')}>All</StyledButtonv2>
                                <StyledButtonv2 to='#' style={{
                                    marginTop: '0px',
                                    borderRadius: '10px',
                                    color: filterType === 'completed' ? '#fff' : 'rgba(255,255,255, 0.8)',
                                    background: filterType === 'completed' ? "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)" : '#1e1619',
                                    boxShadow: filterType === 'completed' ? "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)" : 'none',
                                    fontWeight: filterType === 'completed' ? 'bold' : 'normal',
                                }}
                                                onClick={() => changeFilter('completed')}>Completed</StyledButtonv2>
                            </Box>
                            <Box sx={{
                                display: {
                                    xs: 'none',
                                    lg: 'flex'
                                }
                            }}></Box>
                        </Grid>
                        <Table aria-label="my locks table" className={"locksTable"}>
                            <TableHead sx={{
                                display: {
                                    xs: 'none',
                                    xl: 'table-header-group'
                                }
                            }}>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            color: '#fff',
                                            fontSize: '0.9em',
                                            fontWeight: 'normal',
                                            border: 0,
                                            minWidth: '200px',  // Minimum width to make sure it adjusts responsively
                                            maxWidth: '200px'  // Minimum width to make sure it adjusts responsively
                                        }}>Token</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        fontSize: '0.9em',
                                        border: 0,
                                        fontWeight: 'normal',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        minWidth: '500px',  // Ensures the column doesn't get too small
                                        maxWidth: '500px'  // Ensures the column doesn't get too small
                                    }}>Conditions</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        border: 0,
                                        fontSize: '0.9em',
                                        fontWeight: 'normal',
                                        minWidth: '150px' // Ensure some minimum width for Status column
                                    }}>Status</TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        border: 0,
                                        fontSize: '0.9em',
                                        fontWeight: 'normal',
                                        minWidth: '100px' // Ensure some minimum width for Status column
                                    }}>Value / <span style={{color: '#11ff00'}}>Target</span></TableCell>
                                    <TableCell style={{
                                        color: '#fff',
                                        border: 0,
                                        fontSize: '0.9em',
                                        fontWeight: 'normal',
                                        textAlign: 'end',
                                        width: '100%',
                                        minWidth: '100px'  // Ensure some minimum width for Actions
                                    }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locks && locks.length > 0 ? (
                                    locks.map((lock, index) => (
                                        <TableRow key={lock._id} className={"locksTableRow"}
                                                  sx={{
                                                      display: {
                                                          xs: 'grid !important',
                                                          xl: 'flex !important'
                                                      }
                                                  }}
                                        >
                                            <TableCell style={{
                                                color: '#fff',
                                                minWidth: '200px',
                                                maxWidth: '200px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px'
                                            }}>
                                                {/* Token image or fallback circle */}
                                                <div
                                                    style={{
                                                        width: '30px', // Circle size
                                                        height: '30px',
                                                        borderRadius: '50%', // Makes it circular
                                                        background: lock.tokenData.image ? `url(${lock.tokenData.image}) center/cover` : "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 120%)",
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {!lock.tokenData.image && (
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                color: '#fff',
                                                                fontSize: '0.8em',
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            {lock.tokenData.name.slice(0, 1)}
                                                        </Typography>
                                                    )}
                                                </div>
                                                <div>
                                                    <Typography variant="body1"
                                                                style={{fontWeight: 'bold'}}>{lock.tokenData.name}</Typography>
                                                    <Typography variant="body2" style={{
                                                        fontSize: '0.8em',
                                                        color: 'rgba(255, 255, 255, 0.8)'
                                                    }}>
                                                        {lock.token.slice(0, 6)}...{lock.token.slice(-4)}
                                                        <IconButton onClick={() => handleCopy(lock.token)}
                                                                    style={{
                                                                        color: 'rgba(255, 255, 255, 0.8)',
                                                                        marginLeft: '5px'
                                                                    }}>
                                                            <ContentCopyIcon sx={{fontSize: '0.8rem', padding: '4px'}}/>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => window.open(`https://dexscreener.com/ethereum/${lock.tokenData.pool}`, '_blank')}
                                                            style={{
                                                                color: 'rgba(255, 255, 255, 0.8)',
                                                                marginLeft: '0px'
                                                            }}>
                                                            <BarChartIcon sx={{fontSize: '0.8rem', padding: '4px'}}/>
                                                        </IconButton>
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                minWidth: '500px',
                                                maxWidth: '500px'
                                            }}>
                                                {generateLockStatus(lock)}
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                minWidth: '150px'
                                            }}>
                                                {generateLockProgressBars(lock)}
                                                <InfoRoundedIcon sx={{
                                                    display: 'inline-block',
                                                    fontSize: '0.8rem',
                                                    marginRight: '5px'
                                                }}/><span
                                                style={{color: '#9c9b93', fontSize: '0.8rem'}}>{lock.status}</span>
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                minWidth: '50px'
                                            }}>
                                                <Box sx={{display: {xs: 'block', xl: 'none'}}}>Value / <span
                                                    style={{color: '#11ff00'}}>Target</span></Box>
                                                {lock.currentValue && ( // Only show if there is a current value
                                                    <>
                                                        <Typography variant="body2"
                                                                    style={{fontWeight: 'bold'}}>${lock.currentValue}
                                                            <Typography variant="body2"
                                                                        style={{
                                                                            fontSize: '0.75rem',
                                                                            fontWeight: 'normal',
                                                                            display: "inline",
                                                                            opacity: 0.75
                                                                        }}>&nbsp;${shortNumber(lock.tokenData.mc)}</Typography>
                                                        </Typography>

                                                    </>
                                                )}
                                                {lock.destinationValue && ( // Only show if there is a destination value
                                                    <Typography variant="body2"
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    color: '#11ff00'
                                                                }}>${lock.destinationValue}
                                                        <Typography variant="body2"
                                                                    style={{
                                                                        fontSize: '0.75rem',
                                                                        color: '#11ff00',
                                                                        fontWeight: 'normal',
                                                                        display: "inline",
                                                                        opacity: 0.75
                                                                    }}>&nbsp;${shortNumber(lock.mcToday*lock.multiplier)}</Typography>
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell style={{
                                                color: '#fff',
                                                minWidth: '100px',
                                                width: '100%',
                                                textAlign: 'end'
                                            }}>
                                                {lock.status === 'unlockable' && (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleUnlock(lock.lockId)}
                                                        style={{marginRight: '10px'}}
                                                        sx={{
                                                            color: '#ffffff',
                                                            background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
                                                            boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                                                            borderRadius: '10px',
                                                            fontWeight: '900',
                                                            '&:hover': {
                                                                backgroundColor: '#d6005a',
                                                            }
                                                        }}
                                                    >
                                                        Unlock
                                                    </Button>
                                                )}
                                                {lock.emergencyWithdrawEnabled && lock.status === 'locked' && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleEmergencyWithdraw(lock.lockId)}
                                                        sx={{
                                                            color: '#ff007a',
                                                            backgroundColor: '#333',
                                                            borderRadius: '10px',
                                                            fontWeight: '900',
                                                            '&:hover': {
                                                                backgroundColor: '#222',
                                                                color: '#fff',
                                                            }
                                                        }}
                                                    >
                                                        Emergency Withdraw
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))) : (
                                    <TableRow>
                                        <TableCell colSpan={4}
                                                   style={{
                                                       color: '#fff',
                                                       textAlign: 'center',
                                                       borderBottom: 'none',
                                                       justifyContent: "center",
                                                       display: "flex",
                                                       flex: 1
                                                   }}>
                                            {authStatus === 'authenticated' ?
                                                (locksLoading ? <Loader/> : 'No locks found.')
                                                :
                                                'Please sign in first.'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </Grid>

            {/* Success Popup */}
            <Dialog open={showSuccess} onClose={handleCloseSuccess} sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#1f1f22', // Dark background color
                    color: '#ffffff', // Text color
                }
            }}>
                <DialogTitle sx={{color: '#ff007a'}}>Action Successful</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{color: '#ffffff'}}>
                        Your action has been completed successfully!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} sx={{
                        background: "linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%)",
                        border: "1px solid #252527",
                        boxShadow: "0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5)",
                        borderRadius: '37px',
                        minHeight: '40px',
                        padding: '5px 30px',
                        color: '#ffffff',
                        fontWeight: '900',
                        '&:hover': {
                            backgroundColor: '#d6005a', // Darken button on hover
                        }
                    }}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Error Snackbar */}
            <Snackbar
                open={!!showError}
                autoHideDuration={6000}
                onClose={handleCloseError}
                message={showError}
            />
        </Box>
    );
};

export default MyLocks;
