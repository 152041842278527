import React from 'react';
import {Box, Container} from '@mui/material';
import {Link} from 'react-router-dom';
import {ConnectButton} from '@rainbow-me/rainbowkit';
import TopCenter from './TopCenter';
import TopNav from './TopNav';
import {useAccount} from 'wagmi';
import {AppProps} from "../Shared";
import TopNavLanding from "./TopNavLanding";
import TopNavv2 from "./TopNavv2";

const TopRowv2: React.FC<AppProps> = ({authStatus}) => {
    const {isConnected} = useAccount();

    return (
        <>
            {/* Full width Box with bottom border */}
            <Box
                style={{
                    width: '100vw',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    backgroundColor: 'transparent',
                }}
            >
                <Container
                    maxWidth={false}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '80px',
                        padding: '0', // Remove any extra padding
                    }}
                >
                    {/* Left Column (Logo) with minimum width of 20% */}
                    <Box
                        style={{
                            minWidth: '20%', // Set minimum width to 20%
                            maxWidth: '20%', // Optional: Prevents it from growing past 20%
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                        }}
                    >
                        <Link to="/">
                            <img
                                src="/logowhite.png"
                                alt="App Logo"
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    marginLeft: '20px'
                                }} // Keep the logo responsive
                            />
                        </Link>
                    </Box>

                    <TopNavv2 authStatus={authStatus} />
                </Container>
            </Box>
        </>
    );
};

export default TopRowv2;
