import React from 'react';
import {Box, Container, Link, Typography} from '@mui/material';
import {NavLink} from "react-router-dom";


const Footer: React.FC = () => (
    <Box bgcolor="#150C10">
        <Container>
            <Box display="flex" mt={4} p={0} pt={4} bgcolor="#150C10"
                 style={{margin: '16px'}}
                 justifyContent="space-between"
                 flexWrap="wrap" // Allow wrapping for small screens
                 sx={{
                     flexDirection: {xs: "column", sm: "row"}, // Column for small screens, row for larger screens
                     gap: {xs: 3, sm: 0}, // Add spacing between items for small screens
                     mt: 4,
                     p: 0,
                     pt: 4,
                 }}>
                <Box textAlign={{xs: "center", sm: "left"}} mb={{xs: 2, sm: 0}}>
                    <Link href={"/"}>
                        <img
                            src="/logowhite.png"
                            alt="Moon or Dust"
                            style={{marginLeft: '0px', maxHeight: '25px'}}
                        />
                    </Link>
                </Box>
                <Box>
                    <Typography variant="h4" style={{fontWeight: "bold", color: "#E4E4E7"}}>
                        Sitemap
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link
                        href={"/"} className={"footer-links"}>Home</Link>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link
                        href={"/#howitworks"} className={"footer-links"}>How it works</Link>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link
                        href={"/my-locks"} className={"footer-links"}>My Locks</Link>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link
                        href={"/new-lock"} className={"footer-links"}>New Lock</Link>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h4" style={{fontWeight: "bold", color: "#E4E4E7"}}>
                        Resources
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link
                        href={"#"} className={"footer-links"}>Community
                        Channel</Link>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><NavLink
                        to="/terms-and-conditions" className="footer-links">Terms and Conditions</NavLink>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><NavLink
                        to="/privacy-policy" className="footer-links">Privacy Policy</NavLink>
                    </Typography>
                    <Typography style={{color: '#98939c'}} mt={3}><Link href={"#"} className={"footer-links"}>Need
                        Support?</Link>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4} p={0} pt={4} bgcolor="#150C10"
                 style={{margin: '16px'}}>
                <Typography style={{
                    color: '#98939c',
                    marginRight: '10px',
                    display: 'inline-block',
                    fontSize: '0.8rem'
                }}>&copy; 2024 Moon or
                    Dust</Typography>
            </Box>
        </Container>
    </Box>
);

export default Footer;
