import React, {useEffect} from 'react';
import {Box, Container} from '@mui/material';
import TopRow from './components/TopRow';
import Footer from './components/Footer';
import {RainbowKitProvider, darkTheme} from '@rainbow-me/rainbowkit';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Homev2 from './pages/Homev2';
import '@rainbow-me/rainbowkit/styles.css';
import NewLock from "./pages/NewLock";
import TermsAndConditions from "./pages/TermsAndConditions";
import MyLocks from "./pages/MyLocks";
import {AppProps, getQueryParam} from "./Shared";
import ScrollToTop from "./components/ScrollToTop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TopRowLanding from "./components/TopRowLanding";
import TopRowv2 from "./components/TopRowv2";
import LeftNav from "./components/LeftNav";
import { setCookie } from 'tiny-cookie';
import Referrals from "./pages/Referrals";

const DASHBOARD_PATHS = ['/new-lock', '/my-locks', '/new-lock/', '/my-locks/', '/referrals', '/referrals/'];

const LayoutWrapper: React.FC<{ authStatus: any }> = ({authStatus}) => {
    const location = useLocation();
    const isDashboardLayout = DASHBOARD_PATHS.includes(location.pathname);

    return (
        <>
            {isDashboardLayout && <TopRowv2 authStatus={authStatus}/>}
            {!isDashboardLayout && <TopRowLanding authStatus={authStatus}/>}

            <Box
                sx={{
                    display: isDashboardLayout ? 'flex' : 'block', // Flexbox layout
                    flexDirection: {
                        xs: isDashboardLayout ? 'column' : 'row',
                        lg: 'row'
                    },
                    minHeight: {
                        xs: 'auto',
                        lg: '100vh'
                    },
                    marginBottom: isDashboardLayout ? '50px' : '0px', // Add margin to bottom of page
                }}
            >
                {isDashboardLayout &&
                    <Box
                        style={{
                            minWidth: '300px', // Set minimum width to 20%
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                        }}
                    >
                        <LeftNav/>
                    </Box>
                }

                <Routes>
                    {/* Standard Layout Routes */}
                    <Route path="/" element={<Homev2 authStatus={authStatus}/>}/>
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions authStatus={authStatus}/>}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy authStatus={authStatus}/>}
                    />
                    <Route
                        path="/new-lock"
                        element={<NewLock authStatus={authStatus}/>}
                    />
                    <Route
                        path="/my-locks"
                        element={<MyLocks authStatus={authStatus}/>}
                    />
                    <Route
                        path="/referrals"
                        element={<Referrals authStatus={authStatus}/>}
                    />
                </Routes>
            </Box>

            {/*{isDashboardLayout && <Footer/>}*/}
            {!isDashboardLayout && <Footer/>}
        </>
    );
};

const App: React.FC<AppProps> = ({authStatus}) => {

    useEffect(() => {
        const refid = getQueryParam("refid");
        if (refid) {
            setCookie("refid", refid, {expires: 30, samesite: 'strict'});
        }
    }, []);

    return (
        <Router>
            <ScrollToTop/>
            <RainbowKitProvider theme={darkTheme({
                accentColor: '#ff007a',
                borderRadius: 'none',
            })} modalSize="compact" appInfo={{appName: 'Moon or Dust'}}>
                <Container maxWidth={false} disableGutters>
                    <Box sx={{
                        bgcolor: '#0b0709',
                        minHeight: '100vh',
                        width: '100vw',
                        color: '#ffffff',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <LayoutWrapper authStatus={authStatus}/>
                    </Box>
                </Container>
            </RainbowKitProvider>
        </Router>
    );
};

export default App;
