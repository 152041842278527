import React from 'react';
import CenterModulev2 from "../components/CenterModulev2";
import {Container} from "@mui/material";
import {AppProps} from "../Shared";
import Section1 from "../components/Section1";
import Section3 from "../components/Section3";
import HowItWorks from "../components/HowItWorks";
import Section2 from "../components/Section2";

const Homev2: React.FC<AppProps> = ({authStatus}) => {
    return (
        <>
            <Section1/>
            <Section2/>
            <HowItWorks/>
        </>
    );
};

export default Homev2;
